import { computed, runInAction } from 'mobx';
import Store from '../Store';
import { apiFetch } from '../../components/app/decorators/api';
import { fetchAgesExportCsv, fetchAgesStats, fetchGenderStats } from '@/api';
import AgeModel from '../../models/AgeModel';
import { download } from '../../helpers';
import GenderModel from '../../models/GenderModel';

class AudienceStatsStore extends Store {
    static observables = {
        genders: [],
        ages: [],
    };

    @computed
    get audienceStatsHasData() {
        return {
            agesHasData: this.ages.length > 0,
            gendersHasData: this.genders.length > 0,
        };
    }

    @apiFetch
    async fetchGenders(showId, query) {
        try {
            const { data } = await fetchGenderStats(showId, query);
            runInAction(() => {
                this.genders = data.map((gender) => new GenderModel(this, gender));
            });
        } catch (e) {
            runInAction(() => {
                this.genders = [];
            });
        }
    }

    @apiFetch
    async fetchAges(showId, query) {
        try {
            const { data } = await fetchAgesStats(showId, { ...query });
            runInAction(() => {
                this.ages = data.map((age) => new AgeModel(this, age));
            });
        } catch (e) {
            runInAction(() => {
                this.ages = [];
            });
        }
    }

    @apiFetch
    async fetchAgesExportCsv(showId, start, end) {
        const { axios, ...data } = await fetchAgesExportCsv(showId, { start, end });
        const filename = axios.headers['content-disposition'].match(/filename="(.+)"/)[1];
        download(Object.values(data).join(''), filename, 'txt');
    }
}

export default AudienceStatsStore;
