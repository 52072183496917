import Model from './Model';

class PersonalAccessTokenModel extends Model {
    static attributes = {
        createdAt: 'created_at',
        expiresAt: 'expires_at',
        id: 'id',
        name: 'name',
    };
}

export default PersonalAccessTokenModel;
