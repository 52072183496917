import { useMemo, useState } from 'react';
import { forwardRef } from 'react';
import { Button as RACButton, ButtonProps as RACButtonProps } from 'react-aria-components';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { primaryButtonStyles } from './styles/variants/primary';
import { secondaryButtonStyles } from './styles/variants/secondary';
import { tertiaryButtonStyles } from './styles/variants/tertiary';
import { ghostButtonStyles } from './styles/variants/ghost';
import { dangerButtonStyles } from './styles/variants/danger';
import {
    MagicButtonParticle,
    MagicButtonParticles,
    MagicButtonParticlesWrapper,
    magicButtonStyles,
    useButtonParticles,
} from './styles/variants/magic';
import { smallButtonStyles } from './styles/sizes/small';
import { mediumButtonStyles } from './styles/sizes/medium';
import { largeButtonStyles } from './styles/sizes/large';
import { primaryLinkStyles } from '../Link/styles/variants/primary';
import { secondaryLinkStyles } from '../Link/styles/variants/secondary';
import { dangerLinkStyles } from '../Link/styles/variants/danger';
import { smallLinkStyles } from '../Link/styles/sizes/small';
import { mediumLinkStyles } from '../Link/styles/sizes/medium';
import { largeLinkStyles } from '../Link/styles/sizes/large';

export interface ButtonProps extends RACButtonProps {
    variant?:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'ghost'
        | 'danger'
        | 'magic'
        | 'link-primary'
        | 'link-secondary'
        | 'link-danger';
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    isLoading?: boolean;
    loadingIcon?: React.ReactNode;
    className?: string;
    size?: 'small' | 'medium' | 'large';
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            variant = 'primary',
            size = 'medium',
            startIcon,
            endIcon,
            loadingIcon,
            isLoading = false,
            isDisabled = false,
            ...props
        },
        ref,
    ) => {
        const [isHovered, setIsHovered] = useState(false);
        const disabled = isDisabled || isLoading;
        const nbParticules = size === 'medium' ? 32 : size === 'large' ? 48 : 16;
        const particles = useButtonParticles({ isHovered, nbParticules });

        return (
            <BaseButton
                ref={ref}
                {...props}
                $variant={variant}
                $size={size}
                isDisabled={disabled}
                $isLoading={isLoading}
                onHoverStart={() => setIsHovered(true)}
                onHoverEnd={() => setIsHovered(false)}
            >
                {startIcon}
                {children && <span className="button-content">{children}</span>}
                {endIcon}
                {isLoading && (
                    <LoadingWrapper className="button-spinner-wrapper">
                        {loadingIcon ?? (
                            <SpinnerIcon
                                icon={icon({ name: 'spinner-third', style: 'solid' })}
                                spin
                            />
                        )}
                    </LoadingWrapper>
                )}
                {variant === 'magic' && (
                    <MagicButtonParticlesWrapper>
                        <MagicButtonParticles>
                            {particles.map(({ id, ...particle }) => (
                                <MagicButtonParticle key={id} {...particle} />
                            ))}
                        </MagicButtonParticles>
                    </MagicButtonParticlesWrapper>
                )}
            </BaseButton>
        );
    },
);

export const LoadingWrapper = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: inherit;
`;
const SpinnerIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;

const variantStyles = {
    primary: primaryButtonStyles,
    secondary: secondaryButtonStyles,
    tertiary: tertiaryButtonStyles,
    ghost: ghostButtonStyles,
    danger: dangerButtonStyles,
    magic: magicButtonStyles,
};
const sizeStyles = {
    small: smallButtonStyles,
    medium: mediumButtonStyles,
    large: largeButtonStyles,
};
const linkVariantStyles = {
    'link-primary': primaryLinkStyles,
    'link-secondary': secondaryLinkStyles,
    'link-danger': dangerLinkStyles,
};
const linkSizeStyles = {
    small: smallLinkStyles,
    medium: mediumLinkStyles,
    large: largeLinkStyles,
};
const linkStyles = css<{ $size: ButtonProps['size']; $variant: ButtonProps['variant'] }>`
    background: none;
    border: none;
    padding: 0;

    ${({ $variant }) => linkVariantStyles[$variant as keyof typeof linkVariantStyles]}
    ${({ $size }) => linkSizeStyles[$size as keyof typeof linkSizeStyles]}
`;
const buttonStyles = css<{
    $variant: ButtonProps['variant'];
    $size: ButtonProps['size'];
    $isLoading: boolean;
}>`
    ${({ $variant }) => variantStyles[$variant as keyof typeof variantStyles]}
    ${({ $size }) => sizeStyles[$size as keyof typeof sizeStyles]}
`;

const BaseButton = styled(RACButton)<{
    $variant: ButtonProps['variant'];
    $size: ButtonProps['size'];
    $isLoading: boolean;
}>`
    ${({ $variant }) => ($variant && $variant.startsWith('link-') ? linkStyles : buttonStyles)}
`;

Button.displayName = 'Button';

export default Button;
