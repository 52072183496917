import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const fetchPersonalAccessToken = () => {
    return contentApi({ method: 'GET', target: '/v1/personal-access/tokens' });
};
export const addPersonalAccessToken = (body) => {
    return contentApi({ method: 'POST', target: '/v1/personal-access/tokens', body });
};
export const deletePersonalAccessToken = (tokenId) => {
    return contentApi({ method: 'DELETE', target: `/v1/personal-access/tokens/${tokenId}` });
};
