import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import { psoScoreSuggestedKeywords } from '@/api';
import { z } from 'zod';
import psoScoreKeys from './psoScoreKeys';

interface RawAIKeyword {
    label: string;
    rank: number;
}
export interface useAIKeywordsProps {
    episodeId: string;
    enabled?: boolean;
}

export const AIKeywordSchema = z.object({
    id: z.string(),
    label: z.string(),
});

const AIKeywordsSchema = z.array(AIKeywordSchema);
export type AIKeyword = z.infer<typeof AIKeywordSchema>;

type Key = QueryFunctionContext<ReturnType<typeof psoScoreKeys.aiKeywords>>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await psoScoreSuggestedKeywords(episodeId);
    const formattedData = data.items.map((item: RawAIKeyword) => ({
        id: crypto.randomUUID(),
        label: item.label,
    }));

    // TODO: Do camelcase transformation in Axios interceptor
    return AIKeywordsSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

const useAIKeywords = ({ episodeId, enabled }: useAIKeywordsProps) => {
    return useQuery({
        queryKey: psoScoreKeys.aiKeywords(episodeId),
        queryFn,
        enabled: !!episodeId && enabled,
    });
};

export default useAIKeywords;
