import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAddEpisodeImageMutation from '@queries/episode/useAddEpisodeImageMutation.hook';
import useDeleteEpisodeImageMutation from '@queries/episode/useDeleteEpisodeImageMutation.hook';
import useUpdateChaptersMutation from '@queries/chapter/useUpdateChaptersMutation.hook';
import useUpdateEpisodeMutation from '@queries/episode/useUpdateEpisodeMutation.hook';
import { DEBUG_EPISODE_EDIT_FORM_SAVE } from '@/utils/constants';
import useAddChapterImageMutation from '@queries/chapter/useAddChapterImageMutation.hook';
import useDeleteChapterImageMutation from '@queries/chapter/useDeleteChapterImageMutation.hook';
import episodeKeys from '@queries/episode/episodeKeys';
import aiKeys from '@/queries/ai/aiKeys';

const debug = (...args) => {
    if (DEBUG_EPISODE_EDIT_FORM_SAVE) {
        console.log(...args);
    }
};

const useSaveEpisodeFormMutation = () => {
    const queryClient = useQueryClient();
    const updateEpisodeMutation = useUpdateEpisodeMutation();
    const updateEpisodeChaptersMutation = useUpdateChaptersMutation();
    const addEpisodeImageMutation = useAddEpisodeImageMutation();
    const deleteEpisodeImageMutation = useDeleteEpisodeImageMutation();
    const addChapterImageMutation = useAddChapterImageMutation();
    const deleteChapterImageMutation = useDeleteChapterImageMutation();

    return useMutation({
        mutationFn: ({
            episodeId,
            episode: episodeData,
            chapters,
            chaptersIdsRequiringImageDeletion,
        }) => {
            const { imageUrl, ...episode } = episodeData;
            const promises = [];

            debug(`[Episode form] Starting saving process`);

            if (imageUrl instanceof File) {
                debug(`[Episode form] Saving image for episode ${episodeId}…`);
                addEpisodeImageMutation.mutate(
                    {
                        episodeId,
                        image: imageUrl,
                    },
                    {
                        onSuccess: (data, variables, context) => {
                            debug(
                                `[Episode form] Image for episode ${episodeId} saved with success!`,
                                { data, variables, context },
                            );
                        },
                        onError: (error, variables, context) => {
                            debug(`[Episode form] Failed to save image for episode ${episodeId}`, {
                                error,
                                variables,
                                context,
                            });
                        },
                    },
                );
            }

            if (imageUrl === null) {
                debug(`[Episode form] Deleting image for episode ${episodeId}…`);
                deleteEpisodeImageMutation.mutate(
                    { episodeId },
                    {
                        onSuccess: (data, variables, context) => {
                            debug(
                                `[Episode form] Image for episode ${episodeId} deleted with success!`,
                                { data, variables, context },
                            );
                        },
                        onError: (error, variables, context) => {
                            debug(
                                `[Episode form] Failed to delete image for episode ${episodeId}`,
                                { error, variables, context },
                            );
                        },
                    },
                );
            }

            if (episode && Object.keys(episode).length > 0) {
                debug(`[Episode form] Saving episode ${episodeId}…`);
                const updateEpisodePromise = updateEpisodeMutation.mutateAsync(
                    {
                        episodeId,
                        episode: {
                            ...episode,
                            name: episode.name || undefined, // Allows to save form event if name is an empty string
                        },
                    },
                    {
                        onSuccess: (data, variables, context) => {
                            debug(`[Episode form] Episode ${episodeId} saved with success!`, {
                                data,
                                variables,
                                context,
                            });
                        },
                        onError: (error, variables, context) => {
                            debug(`[Episode form] Failed to save episode ${episodeId}`, {
                                error,
                                variables,
                                context,
                            });
                        },
                    },
                );
                promises.push(updateEpisodePromise);
            }

            if (chapters) {
                const sortedChapters = chapters.toSorted(
                    (chapter1, chapter2) => chapter1.startTime - chapter2.startTime,
                );

                debug(`[Episode form] Saving chapters for episode ${episodeId}…`);
                const updateEpisodeChaptersPromise = updateEpisodeChaptersMutation.mutateAsync(
                    {
                        episodeId,
                        chapters: sortedChapters.map(({ id, ...chapterWithoutId }) => {
                            // If the chapter have an ID generated by the front end, we remove it from the request
                            if (`${id}`.startsWith('local-chapter')) {
                                return chapterWithoutId;
                            }

                            // The chapter already have a back end ID, and we should send it in the request
                            return {
                                id,
                                ...chapterWithoutId,
                            };
                        }),
                    },
                    {
                        onSuccess: (data, variables, context) => {
                            debug(
                                `[Episode form] Chapters for episode ${episodeId} saved with success!`,
                                { data, variables, context },
                            );
                            // Chapters are saved but new images are not saved yet.
                            // To upload an image we need a chapter ID. If the image to upload is associated to a new chapter,
                            // we need a mapping between the front end generated chapter ID (local-chapter-XXX) and the back end ID.
                            //
                            // Example of mapping :
                            // 1234                 -> 1234 (chapter already exist and was fetch by API)
                            // 5678                 -> 5678 (chapter already exist and was fetch by API)
                            // local-chapter-192837 -> 9046 (new chapter saved in the updateEpisodeChaptersMutation)
                            const mapChapterIds = new Map();
                            sortedChapters.forEach(({ id: localId }, index) => {
                                mapChapterIds.set(localId, data[index]?.id);
                            });

                            sortedChapters.forEach((chapter) => {
                                // Searching chapters ID mapping to get back end chapter ID
                                const chapterId = mapChapterIds.get(chapter.id);

                                if (chapter.imageUrl instanceof File) {
                                    debug(`[Episode form] Saving image for chapter ${chapterId}…`);
                                    addChapterImageMutation.mutate({
                                        chapterId,
                                        image: chapter.imageUrl,
                                        episodeId,
                                    });
                                }
                            });

                            // Delete chapter images if necessary
                            chaptersIdsRequiringImageDeletion.forEach((chapterId) => {
                                debug(`[Episode form] Deleting image for chapter ${chapterId}…`);
                                deleteChapterImageMutation.mutate({ chapterId, episodeId });
                            });
                        },
                        onError: (error, variables, context) => {
                            debug(
                                `[Episode form] Failed to save chapters for episode ${episodeId}`,
                                { error, variables, context },
                            );
                        },
                    },
                );
                promises.push(updateEpisodeChaptersPromise);
            }

            debug(`[Episode form] Ending saving process`);
            // Waiting for all promises to be fulfilled or rejected
            return Promise.allSettled(promises);
        },
        onSuccess: (data, { episodeId }) => {
            debug(`[Episode form] Invalidating query for episode ${episodeId}`);
            queryClient.resetQueries({ queryKey: aiKeys.all() });
            return queryClient.invalidateQueries({ queryKey: episodeKeys.detailById(episodeId) });
        },
    });
};

export default useSaveEpisodeFormMutation;
