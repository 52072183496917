import useShowQuery from '@/queries/show/useShowQuery.hook';
import { ENABLE_EPISODE_AI, USER_ROLES } from '../constants';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { USER_OPTIONS } from '../userOptions';
import { authorizeEpisodeAIForUser } from '@/helpers';

interface HasAccessToEpisodeAIProps {
    showId: string;
}

const useHasAccessToEpisodeAI = ({ showId }: HasAccessToEpisodeAIProps) => {
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();

    const guestWithAccess =
        show?.userRole === USER_ROLES.ADMIN && show?.userOptions.includes(USER_OPTIONS.EPISODE_AI);
    const ownerWithAccess =
        show?.userRole === USER_ROLES.OWNER && user?.options.includes(USER_OPTIONS.EPISODE_AI);
    const hasEpisodeAIUserOption = guestWithAccess || ownerWithAccess;

    return (ENABLE_EPISODE_AI || authorizeEpisodeAIForUser(user?.id)) && hasEpisodeAIUserOption;
};

export default useHasAccessToEpisodeAI;
