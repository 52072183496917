import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchPrices } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import priceKeys from './priceKeys';

export type Currency = 'eur' | 'gbp' | 'usd';
export type Offer =
    | 'freemium'
    | 'launch'
    | 'boost'
    | 'supersonic'
    | 'enterprise'
    | 'importRssAuto'
    | 'uploadHours';

export interface Price {
    monthly: {
        base: string;
        extra?: string;
    };
    yearly: {
        base: string;
        extra?: string;
    };
}

export type PriceList = {
    [K in Offer]: Price;
};

export type Prices = {
    [K in Currency]: PriceList;
};

const queryFn = async () => {
    const { axios, ...rest } = await fetchPrices();

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(rest, { deep: true });
};

const usePricesQuery = (): UseQueryResult<Prices> => {
    return useQuery({
        queryKey: priceKeys.allDetails(),
        queryFn,
    });
};

export default usePricesQuery;
