import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

export interface PricingOfferFeatureProps {
    isHighlighted?: boolean;
    isAdditional?: boolean;
    iconColor?: string;
    children: React.ReactNode;
}

const PricingOfferFeature = ({
    isHighlighted,
    isAdditional,
    iconColor,
    children,
}: PricingOfferFeatureProps) => (
    <PricingOfferFeatureWrapper>
        {isAdditional ? (
            <FeatureIcon
                icon={icon({ name: 'plus', style: 'solid' })}
                iconColor={iconColor ? iconColor : '--primary'}
            />
        ) : (
            <FeatureIcon
                icon={icon({ name: 'check', style: 'solid' })}
                iconColor={iconColor ? iconColor : '--success'}
            />
        )}
        <Text fontWeight={isHighlighted ? '--fw-bold' : '--fw-normal'}>{children}</Text>
    </PricingOfferFeatureWrapper>
);

const PricingOfferFeatureWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: nowrap;
`;
const FeatureIcon = styled(FontAwesomeIcon)<{ iconColor: string }>`
    color: ${(props) => props.iconColor && `var(${props.iconColor})`};
    margin-top: 0.125rem;
    font-size: 0.75rem;
`;

export default PricingOfferFeature;
