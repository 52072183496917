import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCallToActionButtons } from '@/api';
import { decamelizeKeys } from 'humps';
import camelcaseKeys from 'camelcase-keys';
import callToActionKeys from './callToActionKeys';

type Target = 'newsletter' | 'player' | 'podcast_page' | 'smartlink';

export interface MutationFnProps {
    showId: string;
    ctaId?: string;
    cta: {
        label: string;
        url: string;
        targets: Target[];
    };
    type?: Target | 'custom';
}

const mutationFn = async ({ ctaId, cta, type }: MutationFnProps) => {
    if (!ctaId) return;
    const { label, targets, ...rest } = cta;
    const payload = {
        ...rest,
        text: label,
        locations: targets,
        type,
    };
    const { data } = await updateCallToActionButtons(ctaId, decamelizeKeys(payload));

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

export default function useUpdateCallToActionButtonMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: callToActionKeys.all(),
            });
        },
    });
}
