import Model from './Model';

class ImportedPodcastsCountersModel extends Model {
    static attributes = {
        done: 'done',
        error: 'error',
        pending: 'pending',
        processing: 'processing',
        total: 'total',
    };
}

export default ImportedPodcastsCountersModel;
