import Model from './Model';

class EpisodeCompareStatsModel extends Model {
    static attributes = {
        id: 'id',
        name: 'name',
        data: {
            fromApi: {
                key: 'data',
                resolve(data) {
                    return data.map((c) => ({
                        downloads: c.downloads,
                        day: c.day,
                    }));
                },
            },
        },
    };
}

export default EpisodeCompareStatsModel;
