import { sendAmplitudeLogEvent } from '@/helpers';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useAmplitudeLogLocationChangeEvent = () => {
    const location = useLocation();

    useEffect(() => {
        sendAmplitudeLogEvent('Page Viewed');
    }, [location]);
};

export default useAmplitudeLogLocationChangeEvent;
