import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useToggleState } from 'react-stately';
import { useFocusRing, useSwitch, VisuallyHidden } from 'react-aria';
import Text from '../Text';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

const StyledLabel = styled(Text).attrs({ forwardedAs: 'label' })`
    display: flex;
    align-items: center;
    opacity: ${(p) => (p.isDisabled ? 0.5 : 1)};
    gap: 0.5rem;
    position: relative; // Use this because of VisuallyHidden styles causing issues with the input position
    cursor: ${(p) => (p.isDisabled ? 'not-allowed' : 'pointer')};
`;
const ToggleOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;
const ToggleInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const ToggleElement = styled.div`
    width: 40px;
    position: relative;
`;
const ToggleBackground = styled.div`
    width: 40px;
    height: 20px;
    border-radius: var(--r-full);
    background: ${(p) => (p.$isSelected ? 'var(--gradient-primary)' : 'var(--neutral300)')};

    ${ToggleElement}:hover & {
        filter: brightness(85%);
    }

    ${(p) =>
        p.isFocusVisible &&
        css`
            box-shadow: 0 0 0 2px var(--primary);
        `}
`;
const ToggleHandle = styled(motion.div)`
    position: absolute;
    top: 2px;
    background: white;
    height: 16px;
    width: 16px;
    border-radius: var(--r-full);

    ${(p) =>
        !p.$isSelected &&
        css`
            left: 2px;
        `}

    ${(p) =>
        p.$isSelected &&
        css`
            right: 2px;
        `}
`;
const Label = styled.label`
    font-weight: var(--fw-semibold);
`;

function Toggle(props) {
    let state = useToggleState(props);
    let ref = useRef();
    let { inputProps } = useSwitch(props, state, ref);
    let { isFocusVisible, focusProps } = useFocusRing();

    return (
        <StyledLabel isDisabled={props.isDisabled}>
            <VisuallyHidden>
                <input {...inputProps} {...focusProps} ref={ref} />
            </VisuallyHidden>
            <ToggleOuterWrapper>
                {props.label && <Label>{props.label}</Label>}
                <ToggleInnerWrapper>
                    <ToggleElement>
                        <ToggleBackground
                            $isSelected={state.isSelected}
                            isFocusVisible={isFocusVisible}
                        />
                        <ToggleHandle
                            $isSelected={state.isSelected}
                            layout
                            transition={{
                                type: 'spring',
                                stiffness: 700,
                                damping: 30,
                            }}
                        />
                    </ToggleElement>
                    {props.children}
                </ToggleInnerWrapper>
            </ToggleOuterWrapper>
        </StyledLabel>
    );
}

Toggle.propTypes = {
    isDisabled: PropTypes.bool,
    isSelected: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
    isReadOnly: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onFocusChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    id: PropTypes.string,
    excludeFromTabOrder: PropTypes.bool,
    children: PropTypes.node,
    label: PropTypes.string,
};

Toggle.defaultProps = {
    isDisabled: false,
    isSelected: false,
    onChange: () => {},
    value: '',
    name: '',
    isReadOnly: false,
    autoFocus: false,
    onFocus: () => {},
    onBlur: () => {},
    onFocusChange: () => {},
    onKeyDown: () => {},
    onKeyUp: () => {},
    id: `${Math.random()}`,
    excludeFromTabOrder: false,
    children: null,
    label: null,
};

export default Toggle;
