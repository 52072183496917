import { useEffect, useState } from 'react';
import play from '@public/icons/play.svg';
import pause from '@public/icons/pause.svg';
import { useResponsive } from '@/utils/hooks/useResponsive';
import posed, { PoseGroup } from 'react-pose';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import MobileOrTablet from '../../ui/molecules/MobileOrTablet';
import Desktop from '../../ui/molecules/Desktop';
import { connect } from '../decorators/connect';
import { formatDuration } from '../../../helpers';
import styled, { css } from 'styled-components';

const playerWidth = 500;
const durationOptions = { h: '', mn: '', sec: '', join: ':', full: true };

const transition = {
    duration: 250,
};

const PlayerPose = posed(DeprecatedPaper)({
    enter: { bottom: 30, transition },
    exit: { bottom: -100, transition },
});

const enhance = connect(({ podcastStore }) => ({
    podcast: podcastStore.playingPodcast,
}));

let audio;

function AushaHeaderPlayer({ podcast }) {
    const [progress, setProgress] = useState(0);
    const [progressResponsive, setProgressResponsive] = useState(0);
    const { isMobileOrTablet } = useResponsive();

    useEffect(() => {
        if (!podcast) return undefined;
        audio = new Audio(podcast.audioUrl);
        audio.addEventListener('timeupdate', onUpdate);
        audio.addEventListener('ended', onEnded);
        audio.play();

        return () => {
            audio.pause();
            audio.removeEventListener('timeupdate', onUpdate);
            audio.removeEventListener('ended', onEnded);
        };
    }, [podcast]);

    useEffect(() => {
        if (!podcast) return;
        if (podcast.isPaused) {
            audio.pause();
        } else {
            audio.play();
        }
    }, [podcast && podcast.isPaused]);

    function onUpdate() {
        if (isMobileOrTablet) {
            const currentTime = (audio.currentTime / audio.duration) * 100;
            setProgressResponsive(currentTime);
        }
        setProgress(audio.currentTime / audio.duration);
    }

    function onEnded() {
        podcast.pause();
        setProgress(0);
    }

    function onClick({ nativeEvent }) {
        const percent = nativeEvent.offsetX / playerWidth;
        audio.currentTime = percent * audio.duration;
    }

    function onClickResp({ nativeEvent }) {
        const percent = nativeEvent.offsetX / nativeEvent.target.clientWidth;
        audio.currentTime = percent * audio.duration;
    }

    if (!podcast || !audio) {
        return null;
    }

    return (
        <>
            <MobileOrTablet>
                <PoseGroup animateOnMount>
                    <PlayerPose
                        key={0}
                        flex
                        pos="fixed"
                        background="rgba(0,0,0,0.4)"
                        y="initial"
                        h={80}
                        style={{ backdropFilter: 'blur(20px)', borderRadius: '50px' }}
                        w="calc(100% - 130px)"
                        mx={20}
                        align="center"
                        p={20}
                    >
                        <DeprecatedIcon
                            as={podcast.isPaused ? play : pause}
                            size={20}
                            color="white"
                            onClick={podcast.playOrPause}
                        />
                        <DeprecatedPaper flex column px={15} w="calc(100% - 20px)">
                            <DeprecatedText size="normal" color="white" ellipsis>
                                {podcast.name}
                            </DeprecatedText>
                            <DeprecatedPaper pos="relative">
                                <DeprecatedPaper
                                    height={4}
                                    z={1}
                                    w={`${progressResponsive}%`}
                                    background="white"
                                    rounded
                                    pos="absolute"
                                />
                                <DeprecatedPaper
                                    onClick={onClickResp}
                                    w="100%"
                                    height={4}
                                    background="--white100"
                                    rounded
                                />
                            </DeprecatedPaper>
                            <DeprecatedPaper flex mt={5}>
                                <DeprecatedText size="verySmall" color="white" opacity={0.4}>
                                    {formatDuration(audio.currentTime, durationOptions)}
                                </DeprecatedText>
                                <DeprecatedText
                                    size="verySmall"
                                    color="white"
                                    ml="auto"
                                    opacity={0.4}
                                >
                                    {`-${formatDuration(
                                        audio.duration - audio.currentTime,
                                        durationOptions,
                                    )}`}
                                </DeprecatedText>
                            </DeprecatedPaper>
                        </DeprecatedPaper>
                    </PlayerPose>
                </PoseGroup>
            </MobileOrTablet>
            <Desktop>
                <DeprecatedPaper height="100%" w={550} justify="center" align="center" flex>
                    <DeprecatedIcon
                        as={podcast.isPaused ? play : pause}
                        color="white"
                        onClick={podcast.playOrPause}
                    />
                    <PlayerOuterWrapper width={playerWidth}>
                        <PlayerWrapper>
                            <DeprecatedText size="small" color="white" ellipsis w={380}>
                                {podcast.name}
                            </DeprecatedText>
                            <DeprecatedText size="small" color="white">
                                {formatDuration(audio.currentTime, durationOptions)} /{' '}
                                {formatDuration(audio.duration, durationOptions)}
                            </DeprecatedText>
                        </PlayerWrapper>
                        <ProgressWrapper>
                            <ProgressTrack onClick={onClick} />
                            <ProgressIndicator width={playerWidth * progress} />
                        </ProgressWrapper>
                    </PlayerOuterWrapper>
                </DeprecatedPaper>
            </Desktop>
        </>
    );
}

const PlayerOuterWrapper = styled.div`
    margin-left: 1.5rem;
    width: ${({ width }) => width}px;
    display: flex;
    align-items: center;
    flex-direction: column;
`;
const PlayerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
const ProgressWrapper = styled.div`
    position: relative;
    height: 0.25rem;
    width: 100%;
`;
const ProgressBase = css`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: var(--r-full);
`;
const ProgressTrack = styled.div`
    ${ProgressBase};
    background-color: var(--white100);
    width: 100%;
`;
const ProgressIndicator = styled.div`
    ${ProgressBase};
    background-color: var(--white);
    width: ${({ width }) => width}px;
`;

export default enhance(AushaHeaderPlayer);
