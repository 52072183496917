import { useQuery } from '@tanstack/react-query';
import { fetchYoutubeVideo } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import youtubeVideoKeys from './youtubeVideoKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const queryFn = async ({ queryKey: [{ episodeId }] }) => {
    const { data } = await fetchYoutubeVideo(episodeId);

    return transform(data);
};

const useYoutubeVideoQuery = (episodeId) => {
    return useQuery({
        queryKey: youtubeVideoKeys.detailById(episodeId),
        queryFn,
        enabled: !!episodeId,
    });
};

export default useYoutubeVideoQuery;
