import { useQuery } from '@tanstack/react-query';
import { fetchSharingProvider } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import providerKeys from '@/queries/provider/providerKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const queryFn = async ({ queryKey: [{ showId, provider }] }) => {
    const { account } = await fetchSharingProvider(showId, provider);

    return transform(account);
};

const useSoundcloudProviderQuery = (showId) => {
    return useQuery({
        queryKey: providerKeys.detailForSoundcloud(showId),
        queryFn,
        enabled: !!showId,
        retry: false,
    });
};

export default useSoundcloudProviderQuery;
