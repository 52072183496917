import autobind from 'autobind-decorator';
import routes from '../routes';
import { buildQuery, parseQuery } from '../api/query';
import { fetchMinimalShows } from '@/api';

const resolvers = {
    podId: (id, { podcastStore }) => podcastStore.byId(id),
    playlistId: (id, { playlistStore }) => playlistStore.firstPlaylistOrById(id),
    showId: (id, { showStore }) => showStore.byId(id),
    campaignId: (id, { campaignStore }) => campaignStore.byId(id),
};

@autobind
class RouterStore {
    constructor(history, match, location, state) {
        this.history = history;
        this.match = match;
        this.location = location;
        this.state = state;
    }

    link(routeName, args = {}, query = {}) {
        const params = { ...this.match.params, ...args };
        const url = routes[routeName].replace(/:(\w+)/gi, (_, e) => params[e] || '');
        return url + buildQuery(query);
    }

    push(routeName, args = {}) {
        const params = { ...this.match.params, ...args };
        this.history.push(routes[routeName].replace(/:(\w+)/gi, (_, e) => params[e]));
    }

    replace(routeName, args = {}) {
        const params = { ...this.match.params, ...args };
        this.history.replace(routes[routeName].replace(/:(\w+)/gi, (_, e) => params[e]));
    }

    goBack(...args) {
        this.history.goBack(...args);
    }

    resolve(paramName) {
        const paramValue = this.params[paramName] || 0;
        return resolvers[paramName](paramValue, this.state);
    }

    async redirectAfterLogin() {
        const referrer = this.location.state?.referrer;
        const showsListRegex = /^\/app\/show\/all/;

        if (referrer && !showsListRegex.test(referrer)) {
            this.history.push(this.location.state.referrer);
            return;
        }

        const shows = await fetchMinimalShows();
        const uniqueShow = (shows?.data?.length === 1 && shows?.data?.[0]) ?? null;

        if (uniqueShow) {
            this.push('menu.episodes', { showId: uniqueShow.id });
            return;
        }

        this.history.push('/app');
    }

    get defaultRedirect() {
        return this.location.state?.referrer || '/app';
    }

    get query() {
        return parseQuery(this.location.search);
    }

    get params() {
        return this.match.params;
    }

    get locationPath() {
        return this.location.pathname;
    }

    resolveByLocation(params) {
        const locationPath =
            this.location.pathname.match(
                /(show\/all\/\d+)|(show\/\d+)|(episodes\/\d+)|(episodes\/recap\/\d+)|(user\/\d+)|(monetize\/manual\/\d+)|(playlists\/\d+)/gm,
            ) || 0;
        let objLocation = [];
        for (let i = 0; i < locationPath.length; i += 1) {
            if (locationPath[i].match(/(show|user)/gm)) {
                objLocation = {
                    ...objLocation,
                    showId: this.state.showStore.byId(locationPath[i].match(/\d+/)[0]),
                };
            } else if (locationPath[i].match(/(episodes)/gm)) {
                objLocation = {
                    ...objLocation,
                    podId: this.state.podcastStore.byId(locationPath[i].match(/\d+/)[0]),
                };
            } else if (locationPath[i].match(/(manual)/gm)) {
                objLocation = {
                    ...objLocation,
                    campaignId: this.state.campaignStore.byId(locationPath[i].match(/\d+/)[0]),
                };
            } else if (locationPath[i].match(/(playlists)/gm)) {
                objLocation = {
                    ...objLocation,
                    playlistId: this.state.playlistStore.byId(locationPath[i].match(/\d+/)[0]),
                };
            }
        }
        return objLocation[params];
    }

    get path() {
        return this.match.path;
    }

    get routeName() {
        const nameAndPath = Object.entries(routes).find(([, path]) => path === this.path);
        if (!nameAndPath) {
            return null;
        }
        return nameAndPath[0];
    }
}

export default RouterStore;
