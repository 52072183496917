import { createContext, useContext, useEffect, useState } from 'react';
import { AICustomPromptFormSchema } from '@/components/EpisodeAI/EpisodeAICustomPrompt/EpisodeAICustomPromptForm/useAICustomPromptFormSchema.hook';
import { useParams } from 'react-router-dom';
import useAITitleSuggestionsQuery from '@queries/ai/useAITitleSuggestionsQuery.hook';
import useAIDescriptionSuggestionsQuery from '@queries/ai/useAIDescriptionSuggestionQuery.hook';
import useAITagsSuggestionsQuery from '@queries/ai/useAITagsSuggestionsQuery.hook';
import useAINewsletterSuggestionsQuery from '@queries/ai/useAINewsletterSuggestionQuery.hook';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';

export type AISettingsMapItem = 'title' | 'description' | 'tags' | 'newsletter';
type SettingsMap = Map<AISettingsMapItem, AICustomPromptFormSchema>;

type IEpisodeAISettingsContext = [
    SettingsMap,
    (type: AISettingsMapItem, data: AICustomPromptFormSchema) => void,
];

const defaultSettings = {
    customPrompt: '',
    saveAsDefault: false,
};
const initialSettingsState: SettingsMap = new Map([
    ['title', defaultSettings],
    ['description', defaultSettings],
    ['tags', defaultSettings],
    ['newsletter', defaultSettings],
]);

const EpisodeAISettingsContext = createContext<IEpisodeAISettingsContext>([
    initialSettingsState,
    () => {},
]);

const getSessionSettings = (episodeId: string) => {
    const settingsMap = new Map();
    initialSettingsState.forEach((value, key) => {
        const sessionSettings = sessionStorage.getItem(`episode-${episodeId}-ai-${key}-settings`);
        const defaultSettings = sessionSettings ? JSON.parse(sessionSettings) : value;
        settingsMap.set(key, defaultSettings);
    });
    return settingsMap;
};

interface EpisodeAISettingsContextProviderProps {
    children: React.ReactNode;
}

const EpisodeAISettingsContextProvider = ({ children }: EpisodeAISettingsContextProviderProps) => {
    const { episodeId } = useParams<{ showId: string; episodeId: string }>();
    const [_, dispatch] = useEpisodeAILoading();
    const [settings, setSettings] = useState(getSessionSettings(episodeId));
    const [settingsToRefetch, setSettingsToRefetch] = useState<AISettingsMapItem | null>(null);
    const defaultSettings = {
        customPrompt: '',
        saveAsDefault: false,
    };
    const titleSettings = settings.get('title') || defaultSettings;
    const descriptionSettings = settings.get('description') || defaultSettings;
    const tagsSettings = settings.get('tags') || defaultSettings;
    const newsletterSettings = settings.get('newsletter') || defaultSettings;

    const titleSuggestionsQuery = useAITitleSuggestionsQuery({
        episodeId,
        settings: titleSettings,
        delay: 2000,
    });
    const descriptionSuggestionsQuery = useAIDescriptionSuggestionsQuery({
        episodeId,
        settings: descriptionSettings,
        delay: 2000,
    });
    const tagsSuggestionsQuery = useAITagsSuggestionsQuery({
        episodeId,
        settings: tagsSettings,
        delay: 2000,
    });
    const newsletterSuggestionsQuery = useAINewsletterSuggestionsQuery({
        episodeId,
        settings: newsletterSettings,
        delay: 2000,
    });

    useEffect(() => {
        if (!settingsToRefetch) return;
        switch (settingsToRefetch) {
            case 'title':
                titleSuggestionsQuery.refetch();
                dispatch({
                    type: EPISODE_AI_LOADING_ACTIONS.START,
                    payload: 'title',
                });
                break;
            case 'description':
                descriptionSuggestionsQuery.refetch();
                dispatch({
                    type: EPISODE_AI_LOADING_ACTIONS.START,
                    payload: 'description',
                });
                break;
            case 'tags':
                tagsSuggestionsQuery.refetch();
                dispatch({
                    type: EPISODE_AI_LOADING_ACTIONS.START,
                    payload: 'tags',
                });
                break;
            case 'newsletter':
                newsletterSuggestionsQuery.refetch();
                dispatch({
                    type: EPISODE_AI_LOADING_ACTIONS.START,
                    payload: 'newsletter',
                });
                break;
        }
        setSettingsToRefetch(null);
    }, [settingsToRefetch]);

    const handleChangeSettings = (type: AISettingsMapItem, data: AICustomPromptFormSchema) => {
        if (!settings.has(type)) return;
        const newSettings: SettingsMap = new Map(settings);
        newSettings.set(type, data);
        setSettings(newSettings);
        setSettingsToRefetch(type);
    };

    return (
        <EpisodeAISettingsContext.Provider value={[settings, handleChangeSettings]}>
            {children}
        </EpisodeAISettingsContext.Provider>
    );
};

export const useEpisodeAISettings = (): IEpisodeAISettingsContext => {
    const context = useContext(EpisodeAISettingsContext);
    if (!context) {
        throw new Error(
            'useEpisodeAISettings must be used within a EpisodeAISettingsContextProvider',
        );
    }
    return context;
};

export default EpisodeAISettingsContextProvider;
