import { useMutation, useQueryClient } from '@tanstack/react-query';
import { psoScoreFollowKeyword } from '@/api';
import psoKeys from '../pso/psoKeys';
import camelcaseKeys from 'camelcase-keys';

interface MutationFnProps {
    showId: string;
    keyword: string | null;
}

const mutationFn = async ({ showId, keyword }: MutationFnProps) => {
    const { data } = await psoScoreFollowKeyword(showId, keyword);

    return camelcaseKeys(data, { deep: true });
};

export const useFollowKeywordMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (_, { showId }) => {
            queryClient.invalidateQueries({
                queryKey: psoKeys.globalConfiguration(showId),
            });
            queryClient.invalidateQueries({
                queryKey: psoKeys.keywordQuota(showId),
            });
        },
    });
};

export default useFollowKeywordMutation;
