import { computed } from 'mobx';
import Model from './Model';
import Progress from './Progress';

const resolve = (x, store) => (x ? new Progress(store, x) : undefined);

class AudioProgressModel extends Model {
    static attributes = {
        waveformProgress: {
            fromApi: { key: 'generatewaveform', resolve },
        },
        audioProgress: {
            fromApi: { key: 'converttomp3', resolve },
        },
        chapterProgress: {
            fromApi: { key: 'readchapters', resolve },
        },
    };

    @computed
    get isDone() {
        return (
            (this.waveformProgress?.isDone &&
                this.audioProgress?.isDone &&
                this.chapterProgress?.isDone) ??
            false
        );
    }

    @computed
    get hasFailed() {
        return (
            (this.waveformProgress?.hasFailed ||
                this.audioProgress?.hasFailed ||
                this.chapterProgress?.hasFailed) ??
            false
        );
    }
}

export default AudioProgressModel;
