import { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';
import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import SparklesIcon from '@public/icons/sparkles-nebula.svg';
import Button from '@/components/Button';
import { FormattedMessage } from 'react-intl';
import { useResponsive } from '@hooks/useResponsive';
import { useParams } from 'react-router';
import useHasAccessToEpisodeAI from '@hooks/useHasAccessToEpisodeAI.hook';
import EpisodeAILoader from '../EpisodeAILoader';
import AIPreferencesIcon from '../AIPreferencesIcon';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import EpisodeAICustomPromptModal from '@/components/EpisodeAI/EpisodeAICustomPromptModal';
import { AIFieldType } from '@/components/EpisodeAI/types';
import { useBodyToastQueue } from '@/utils/hooks/useBodyToastQueue.hook';
import { UI_VARIANTS } from '@/utils/constants';

interface AISuggestionsPanelProps {
    children?: ReactNode;
    title?: ReactNode;
    type: AIFieldType;
    onClose: () => void;
    isLoading: boolean;
    'data-testid': string;
    className?: string;
    disablePreferences?: boolean;
    onDislike?: () => void;
}

const EpisodeAISuggestionsPanel = ({
    children,
    title,
    type,
    onClose,
    isLoading,
    'data-testid': dataTestId,
    className,
    disablePreferences = false,
    onDislike,
}: AISuggestionsPanelProps) => {
    const { isDesktopLarge } = useResponsive();
    const { showId } = useParams<{ showId: string }>();
    const hasAccessToEpisodeAI = useHasAccessToEpisodeAI({ showId });
    const episode = useEpisodeBeingEdited();
    const [customPromptModalIsOpen, setCustomPromptModalIsOpen] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const toast = useBodyToastQueue();

    if (!hasAccessToEpisodeAI) return null;

    if (isLoading) {
        return (
            <EpisodeAILoader className={className} data-testid={dataTestId}>
                <FormattedMessage defaultMessage="AI est en train d’écrire" />
            </EpisodeAILoader>
        );
    }

    const handleDislike = () => {
        if (isDisliked) return;
        setIsDisliked(true);
        onDislike?.();
        toast.add(
            <FormattedMessage defaultMessage="Merci beaucoup pour votre contribution 🙏. Votre avis nous est précieux pour perfectionner Ausha Intelligence ! " />,
            {
                icon: <ToastIcon icon={icon({ name: 'heart', style: 'solid' })} />,
                variant: UI_VARIANTS.PRIMARY,
            },
        );
    };

    return (
        <Panel data-testid={dataTestId} className={className}>
            {children}
            <Footer>
                <PanelTitle>
                    <Sparkles />
                    <Text color="--primary-magic" fontWeight="--fw-semibold">
                        {title}
                    </Text>
                </PanelTitle>
                <ActionButtons>
                    <ButtonsGroup>
                        {!disablePreferences && (
                            <ActionButton
                                variant="ghost"
                                startIcon={<AIPreferencesIcon />}
                                onPress={() => setCustomPromptModalIsOpen(true)}
                                aria-label="AI Preferences"
                                isDisabled={episode?.aiFreeTrialEnabled}
                            >
                                {isDesktopLarge && (
                                    <FormattedMessage defaultMessage="Préférences d'IA" />
                                )}
                            </ActionButton>
                        )}
                        <DislikeButton
                            variant="ghost"
                            startIcon={
                                <Icon icon={icon({ name: 'thumbs-down', style: 'solid' })} />
                            }
                            aria-label="Dislike AI Suggestions"
                            onPress={handleDislike}
                            $isActive={isDisliked}
                        >
                            {isDesktopLarge && <FormattedMessage defaultMessage="Je n'aime pas" />}
                        </DislikeButton>
                    </ButtonsGroup>
                    <ActionButton
                        variant="ghost"
                        onPress={onClose}
                        startIcon={<CloseIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
                        aria-label="Close AI Suggestions Panel"
                        data-testid={`${dataTestId}-close-button`}
                    />
                </ActionButtons>
            </Footer>
            <EpisodeAICustomPromptModal
                isOpen={customPromptModalIsOpen}
                onOpenChange={setCustomPromptModalIsOpen}
                type={type}
            />
        </Panel>
    );
};

const Panel = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    margin-top: -0.5rem;
    margin-left: 0.5rem;
    padding: 0.75rem;
    width: calc(100% - 1rem);
    background: var(--gradient-aurora);
    border-radius: var(--r-m);
    box-shadow: var(--s-aurora);
`;
const PanelTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const Sparkles = styled(SparklesIcon)`
    margin-top: 2px;
    width: 1rem;
    height: 1rem;
`;
const Footer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
    }
`;
const ActionButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        justify-content: flex-end;
    }
`;
const ButtonsGroup = styled.div`
    display: flex;
    gap: 1rem;
    margin-left: 1.5rem;
`;
const ActionButton = styled(Button)`
    padding: 0;
    color: var(--primary-magic);
    font-weight: var(--fw-semibold);
`;
const DislikeButton = styled(ActionButton)<{ $isActive: boolean }>`
    ${({ $isActive }) =>
        $isActive &&
        css`
            color: var(--alert500);
            pointer-events: none;
        `}
`;
const Icon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const ToastIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;

export default EpisodeAISuggestionsPanel;
