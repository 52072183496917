import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import CTAForm from '@app/molecules/CTAForm';
const CTACreateButtonModal = ({ isOpened, onClose, state }) => {
    return (
        <DeprecatedModal
            isOpened={isOpened}
            onClose={onClose}
            w={600}
            title={<FormattedMessage defaultMessage="Ajouter un nouveau bouton" />}
        >
            <CTAForm
                onClose={onClose}
                mode="add"
                type={state.item.type || 'custom'}
                defaultValues={{
                    label: state.item.text || '',
                    url: state.item.url || '',
                }}
            />
        </DeprecatedModal>
    );
};

export default CTACreateButtonModal;
