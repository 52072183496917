import Model from './Model';

class GenderModel extends Model {
    static attributes = {
        gender: 'gender',
        count: 'count',
        percentage: 'percentage',
    };
}

export default GenderModel;
