import { AICustomPromptFormSchema } from '@/components/EpisodeAI/EpisodeAICustomPrompt/EpisodeAICustomPromptForm/useAICustomPromptFormSchema.hook';

const aiKeys = {
    all: () => [{ entity: 'ai' }],
    allDetails: () => [{ ...aiKeys.all()[0], scope: 'detail' }],
    title: () => [{ ...aiKeys.all()[0], subscope: 'title' }],
    titleDetails: (rawEpisodeId: string, settings: AICustomPromptFormSchema) => [
        { ...aiKeys.title()[0], episodeId: Number(rawEpisodeId), settings },
    ],
    description: () => [{ ...aiKeys.all()[0], subscope: 'description' }],
    descriptionDetails: (rawEpisodeId: string, settings: AICustomPromptFormSchema) => [
        {
            ...aiKeys.description()[0],
            episodeId: Number(rawEpisodeId),
            settings,
        },
    ],
    tags: () => [{ ...aiKeys.all()[0], subscope: 'tags' }],
    tagsDetails: (rawEpisodeId: string, settings: AICustomPromptFormSchema) => [
        { ...aiKeys.tags()[0], episodeId: Number(rawEpisodeId), settings },
    ],
    newsletter: () => [{ ...aiKeys.all()[0], subscope: 'newsletter' }],
    newsletterDetails: (rawEpisodeId: string, settings: AICustomPromptFormSchema) => [
        {
            ...aiKeys.newsletter()[0],
            episodeId: Number(rawEpisodeId),
            settings,
        },
    ],
    chapters: () => [{ ...aiKeys.all()[0], subscope: 'chapters' }],
    chaptersDetails: (rawEpisodeId: string) => [
        {
            ...aiKeys.chapters()[0],
            episodeId: Number(rawEpisodeId),
        },
    ],
    summaryDetails: (rawEpisodeId: string) => [
        { ...aiKeys.allDetails()[0], episodeId: Number(rawEpisodeId), subscope: 'summary' },
    ],
    customPrompts: (rawShowId: string) => [
        { ...aiKeys.all()[0], showId: Number(rawShowId), subscope: 'customPrompts' },
    ],
};

export default aiKeys;
