import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import { psoScoreTargetKeyword } from '@/api';
import { z } from 'zod';
import psoScoreKeys from './psoScoreKeys';

export interface useTargetKeywordQueryProps {
    episodeId: string;
}

export const TargetKeywordSchema = z.object({
    keyword: z.string(),
});

export type TargetKeyword = z.infer<typeof TargetKeywordSchema>;

type Key = QueryFunctionContext<ReturnType<typeof psoScoreKeys.targetKeyword>>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await psoScoreTargetKeyword(episodeId);

    // TODO: Do camelcase transformation in Axios interceptor
    return TargetKeywordSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useTargetKeywordQuery = ({ episodeId }: useTargetKeywordQueryProps) => {
    return useQuery({
        queryKey: psoScoreKeys.targetKeyword(episodeId),
        queryFn,
        enabled: !!episodeId,
    });
};

export default useTargetKeywordQuery;
