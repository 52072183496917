import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import CTAForm from '@app/molecules/CTAForm';

import useCallToActionButtonsQuery from '@/queries/callToAction/useCallToActionButtonsQuery.hook';
import { useParams } from 'react-router';

const CTAEditButtonModal = ({ isOpened, onClose, state }) => {
    const { showId } = useParams();
    const callToActionButtons = useCallToActionButtonsQuery({ showId });

    if (callToActionButtons.isLoading || callToActionButtons.isError) {
        return null;
    }

    const targetItem = callToActionButtons.data.find((item) => item.id === state.item.id);

    if (!targetItem) {
        return null;
    }

    return (
        <DeprecatedModal
            isOpened={isOpened}
            onClose={onClose}
            w={600}
            title={<FormattedMessage defaultMessage="Ajouter un nouveau bouton" />}
        >
            <CTAForm
                onClose={onClose}
                values={{
                    id: targetItem.id,
                    label: targetItem.text,
                    url: targetItem.url,
                    targets: targetItem.locations,
                    type: targetItem.type,
                }}
                mode="edit"
            />
        </DeprecatedModal>
    );
};

export default CTAEditButtonModal;
