const episodeKeys = {
    all: () => [{ entity: 'episode' }],
    allLists: () => [{ ...episodeKeys.all()[0], scope: 'list' }],
    listByShowId: (
        showId: string,
        {
            q = '',
            page = 1,
            perPage = 15,
            sort = null,
            transformer = null,
            seasonsIds = [],
            states = null,
            publishedDate = null,
            hasClip = false,
            isListenable = false,
        } = {},
    ) => [
        {
            ...episodeKeys.allLists()[0],
            showId,
            q,
            page,
            perPage,
            sort,
            transformer,
            seasonsIds,
            states,
            publishedDate,
            hasClip,
            isListenable,
        },
    ],
    listByShowIdInfinite: (
        showId: string,
        query: string,
        perPage: number,
        status?: string[] | null,
    ) => [
        {
            ...episodeKeys.allLists()[0],
            showId,
            query,
            perPage,
            status,
        },
    ],
    listMinimalByShowId: (
        showId: string,
        page?: number,
        perPage?: number,
        searchQuery?: string,
        withClip?: boolean,
        episodeStatus?: string | null,
    ) => [
        {
            ...episodeKeys.allLists()[0],
            showId,
            searchQuery,
            page,
            perPage,
            withClip,
            episodeStatus,
        },
    ],
    listListenableByShowId: (showId: string) => [
        {
            ...episodeKeys.allLists()[0],
            showId,
        },
    ],
    allDetails: () => [{ ...episodeKeys.all()[0], scope: 'detail' }],
    detailById: (rawEpisodeId: string) => [
        { ...episodeKeys.allDetails()[0], episodeId: Number(rawEpisodeId) },
    ],
    detailByPublicId: (rawEpisodePublicId: string) => [
        { ...episodeKeys.allDetails()[0], episodePublicId: Number(rawEpisodePublicId) },
    ],
};

export default episodeKeys;
