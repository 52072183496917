import { runInAction } from 'mobx';
import Store from './Store';
import { apiCall, apiFetch } from '../components/app/decorators/api';
import {
    deleteClipFormat,
    fetchClipFormat,
    fetchClipFormatsOfPodcast,
    fetchClipFormatsOfShow,
    updateClipFormat,
} from '@/api';
import PaginationModel from '../models/PaginationModel';
import ClipFormatModel from '../models/ClipFormatModel';
import { FormattedMessage } from 'react-intl';
import { modalToastQueue } from '@/components/ui/molecules/GlobalToastRegions';
import { UI_VARIANTS } from '@/utils/constants';

class ClipFormatStore extends Store {
    static observables = {
        clipFormats: [],
        pagination: {},
    };

    @apiFetch
    async fetchClipFormat(clipFormatId) {
        const { data } = await fetchClipFormat(clipFormatId);
        const existingClipFormat = this.clipFormats.find((clipFormat) => clipFormat.id === data.id);
        if (!existingClipFormat) {
            this.clipFormats.push(new ClipFormatModel(this, data));
        } else {
            existingClipFormat.updateData(data);
            existingClipFormat.succeeded &&
                modalToastQueue.add(
                    <FormattedMessage defaultMessage="Le clip a bien été généré" />,
                    {
                        variant: UI_VARIANTS.SUCCESS,
                        timeout: 5000,
                    },
                );
        }
    }

    @apiFetch
    async fetchClipFormatsOfShow(showId, page = 1, perPage = 16) {
        const { data, meta } = await fetchClipFormatsOfShow(showId, page, perPage);
        runInAction(() => {
            this.clipFormats = data.map((json) => new ClipFormatModel(this, json));
            this.pagination = new PaginationModel(this, meta.pagination);
        });
    }

    @apiFetch
    async fetchClipFormatsOfPodcast(episodeId, provider) {
        const { data } = await fetchClipFormatsOfPodcast(episodeId);
        runInAction(() => {
            this.clipFormats = data
                .map((json) => new ClipFormatModel(this, json))
                .filter(
                    (clip) =>
                        clip.status === 'done' &&
                        (provider === 'instagram' ? clip.format !== 'story' : true),
                );
        });
    }

    @apiCall
    async updateClipFormat(clipFormat) {
        const { data } = await updateClipFormat(clipFormat.id, clipFormat);
        runInAction(() => {
            clipFormat.updateData(data);
        });
    }

    @apiCall
    async toggleClipFormatFullCover(clipFormatId, fullCover) {
        const { data } = await updateClipFormat(clipFormatId, { full_cover: fullCover });
        return data ? data.full_cover : false;
    }

    @apiCall
    async deleteClipFormat(clipFormat) {
        await deleteClipFormat(clipFormat.id);
        runInAction(() => {
            this.clipFormats.remove(clipFormat);
        });
    }
}

export default ClipFormatStore;
