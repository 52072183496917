import { createContext, ReactNode, useContext, useReducer, FC, Dispatch } from 'react';
import { Speaker } from './transcriptConverter';

type SpeakerMap = Map<number, string>;

export const SPEAKER_ACTION = {
    ADD_SPEAKER: 'ADD_SPEAKER',
    RENAME_SPEAKER: 'RENAME_SPEAKER',
    DELETE_SPEAKER: 'DELETE_SPEAKER',
} as const;

interface AddSpeakerAction {
    type: typeof SPEAKER_ACTION.ADD_SPEAKER;
    name: string;
}

interface RenameSpeakerAction {
    type: typeof SPEAKER_ACTION.RENAME_SPEAKER;
    id: number;
    name: string;
}

interface DeleteSpeakerAction {
    type: typeof SPEAKER_ACTION.DELETE_SPEAKER;
    id: number;
}

type SpeakerAction = AddSpeakerAction | RenameSpeakerAction | DeleteSpeakerAction;

interface SpeakerContextState {
    speakers: SpeakerMap;
    dispatch: Dispatch<SpeakerAction>;
}

const SpeakerContext = createContext<SpeakerContextState | undefined>(undefined);

/**
 * Generates a new unique ID for a speaker by finding the highest numeric ID and adding one.
 * Speakers IDs start at zero.
 */
const generateNewSpeakerId = (speakerIds: number[]): number => {
    const numericIds = speakerIds.filter((num) => !isNaN(num));
    const maxId = numericIds.length > 0 ? Math.max(...numericIds) : -1;
    return maxId + 1;
};

/**
 * Reducer function to manage the speaker state based on dispatched actions.
 */
const speakerReducer = (state: SpeakerMap, action: SpeakerAction): SpeakerMap => {
    const nextState = new Map(state); // Ensure immutability by creating a new map.

    switch (action.type) {
        case SPEAKER_ACTION.ADD_SPEAKER: {
            const newId = generateNewSpeakerId(Array.from(nextState.keys()));
            nextState.set(newId, action.name);
            break;
        }
        case SPEAKER_ACTION.RENAME_SPEAKER: {
            if (nextState.has(action.id)) {
                nextState.set(action.id, action.name);
            }
            break;
        }
        case SPEAKER_ACTION.DELETE_SPEAKER: {
            nextState.delete(action.id);
            break;
        }
        default:
            // Return the current state if action type is unrecognized.
            return state;
    }

    return nextState;
};

interface SpeakerProviderProps {
    initialSpeakers: Speaker[];
    children: ReactNode;
}

export const SpeakerProvider: FC<SpeakerProviderProps> = ({ initialSpeakers, children }) => {
    const initialState = new Map(initialSpeakers.map(({ id, name }) => [id, name]));
    const [state, dispatch] = useReducer(speakerReducer, initialState);

    return (
        <SpeakerContext.Provider value={{ speakers: state, dispatch }}>
            {children}
        </SpeakerContext.Provider>
    );
};

export const useSpeakers = (): SpeakerContextState => {
    const context = useContext(SpeakerContext);
    if (!context) {
        throw new Error('useSpeakers must be used within a SpeakerProvider');
    }
    return context;
};
