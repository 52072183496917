import Stack from '@ui/layout/Stack';
import EpisodeEditFormOptionsSeasonField from '@app/organisms/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsSeasonField';
import EpisodeEditFormOptionsTypeField from '@app/organisms/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsTypeField';
import EpisodeEditFormOptionsExplicitContentField from '@app/organisms/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsExplicitContentField';
import EpisodeEditFormOptionsPublishOnYoutubeField from '@app/organisms/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsPublishOnYoutubeField';
import { USER_OPTIONS } from '@/utils/userOptions';
import EpisodeEditFormOptionsPublishOnSoundcloudField from '@app/organisms/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsPublishOnSoundcloudField';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import NextStepButton from '@app/organisms/EpisodeEditForm/NextStepButton';

const EpisodeEditFormOptions = () => {
    const episode = useEpisodeBeingEdited();

    return (
        <Stack $gap="2rem">
            <EpisodeEditFormOptionsSeasonField />
            <EpisodeEditFormOptionsTypeField />
            <EpisodeEditFormOptionsExplicitContentField />
            <EpisodeEditFormOptionsPublishOnYoutubeField
                isAllowed={episode.show.userOptions.includes(USER_OPTIONS.BROADCAST_YOUTUBE)}
            />
            <EpisodeEditFormOptionsPublishOnSoundcloudField
                isAllowed={episode.show.userOptions.includes(USER_OPTIONS.SOUNDCLOUD)}
            />
            <NextStepButton />
        </Stack>
    );
};

export default EpisodeEditFormOptions;
