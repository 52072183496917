import { FC, useState, createContext, useContext, ReactNode } from 'react';

interface TPsoScoreContext {
    enabled: boolean;
    setEnabled: (enabled: boolean) => void;
    psoScore: number | null;
    setPsoScore: (score: number | null) => void;
    targetKeyword: string | null;
    setTargetKeyword: (targetKeyword: string | null) => void;
}

const PsoScoreContext = createContext<TPsoScoreContext | undefined>(undefined);

interface PsoScoreContextProviderProps {
    children: ReactNode;
}

export const PsoScoreContextProvider: FC<PsoScoreContextProviderProps> = ({ children }) => {
    const [enabled, setEnabled] = useState(false);
    const [psoScore, setPsoScore] = useState<number | null>(null);
    const [targetKeyword, setTargetKeyword] = useState<string | null>(null);
    const contextValue = {
        enabled,
        setEnabled,
        psoScore,
        setPsoScore,
        targetKeyword,
        setTargetKeyword,
    };

    return <PsoScoreContext.Provider value={contextValue}>{children}</PsoScoreContext.Provider>;
};

export const usePsoScoreContext = () => {
    const context = useContext(PsoScoreContext);
    if (context === undefined) {
        throw new Error('usePsoScoreContext must be used within a PsoScoreContextProvider');
    }
    return context;
};
