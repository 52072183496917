import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchCallToActionButtons } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import callToActionKeys from './callToActionKeys';
import { z } from 'zod';

export const ActionButtonSchema = z.object({
    id: z.number(),
    locations: z.array(z.enum(['newsletter', 'player', 'podcast_page', 'smartlink'])),
    showId: z.number(),
    text: z.string(),
    type: z.string(),
    url: z.string(),
});
const ActionButtonsSchema = z.array(ActionButtonSchema);

type CtaType = 'custom' | 'newsletter' | 'player' | 'podcast_page' | 'smartlink';
type InputProps = { showId: string; type?: CtaType };
type Key = QueryFunctionContext<ReturnType<typeof callToActionKeys.detailByLocation>>;
export type ActionButton = z.infer<typeof ActionButtonSchema>;

const queryFn = async ({ queryKey: [{ showId, type }] }: Key) => {
    const response = await fetchCallToActionButtons(showId, type);

    // TODO: Do camelcase transformation in Axios interceptor
    return ActionButtonsSchema.parse(camelcaseKeys(response.data, { deep: true }));
};

const useCallToActionButtonsQuery = ({ showId, type }: InputProps) => {
    return useQuery({
        queryKey: callToActionKeys.detailByLocation({ showId, type }),
        queryFn,
        enabled: !!showId,
    });
};

export default useCallToActionButtonsQuery;
