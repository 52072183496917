import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import useCTAFormSchema, { CTAFormSchema } from './useCTAFormSchema.hook';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputTextController } from '@/components/ui/atoms/InputText';
// import Button from '@/components/ui/atoms/Button';
import Button from '@/components/Button';
import styled from 'styled-components';

import Checkbox from '@ui/Checkbox';
import { CheckboxGroupController } from '@ui/CheckboxGroup';
import useWebsiteSettingsQuery from '@/queries/websiteSettings/useWebsiteSettingsQuery.hook';
import useSmartlinkSettingsQuery from '@/queries/smartlinkSetting/useSmartlinkSettingsQuery.hook';
import useShowNewsletterTemplateQuery from '@/queries/newsletter/useShowNewsletterTemplateQuery.hook';
import useAddCallToActionButtonMutation from '@/queries/callToAction/useAddCallToActionButtonMutation.hook';
import useUpdateCallToActionButtonMutation from '@/queries/callToAction/useUpdateCallToActionButtonMutation.hook';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';

type CtaType = 'custom' | 'podcast_page' | 'smartlink' | 'newsletter';
interface CTAFormProps {
    onClose: () => void;
    values?: CTAFormSchema & { id: string; type: CtaType };
    defaultValues?: CTAFormSchema;
    mode?: 'add' | 'edit';
    type: CtaType;
}

const CTAForm = ({ onClose, values, defaultValues, mode, type }: CTAFormProps) => {
    const { showId }: { showId: string } = useParams();
    const toast = useModalToastQueue();
    const schema = useCTAFormSchema();
    const { data: websiteSettings } = useWebsiteSettingsQuery({ showId });
    const { data: smartlinkSettings } = useSmartlinkSettingsQuery(showId);
    const { data: newsletterSettings } = useShowNewsletterTemplateQuery(showId);

    const addCta = useAddCallToActionButtonMutation();
    const updateCta = useUpdateCallToActionButtonMutation();

    const { control, handleSubmit, formState } = useForm<CTAFormSchema>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: mode === 'edit' ? values : defaultValues,
    });

    const handleSuccess = () => {
        onClose();
        toast.success(
            <FormattedMessage defaultMessage="Les modifications ont bien été enregistrées. 👌" />,
        );
    };
    const handleError = () => {
        toast.alert(
            <FormattedMessage defaultMessage="Une erreur est survenue, merci de réessayer ultérieurement" />,
        );
    };

    const onSubmit = (formData: CTAFormSchema) => {
        if (mode === 'add') {
            addCta.mutate(
                {
                    showId,
                    type,
                    cta: formData,
                },
                {
                    onSuccess: () => handleSuccess(),
                    onError: () => handleError(),
                },
            );
            return;
        }

        updateCta.mutate(
            {
                showId,
                ctaId: values?.id,
                type: values?.type || 'custom',
                cta: formData,
            },
            {
                onSuccess: () => handleSuccess(),
                onError: () => handleError(),
            },
        );
    };

    return (
        <Form onSubmit={handleSubmit((formData) => onSubmit(formData))}>
            <FormSection>
                <InputTextController
                    control={control}
                    name="label"
                    label={<FormattedMessage defaultMessage="Texte du bouton" />}
                    isRequired={true}
                    maxLength={40}
                />
                <InputTextController
                    control={control}
                    name="url"
                    label={<FormattedMessage defaultMessage="URL du bouton" />}
                    isRequired={true}
                    isReadOnly={type !== 'custom'}
                />

                <CheckboxGroupWrapper>
                    <CheckboxGroupLabel>
                        <FormattedMessage defaultMessage="Ajouter le bouton sur" />
                    </CheckboxGroupLabel>
                    <CheckboxGroupController control={control} name="targets">
                        <Checkbox
                            value="podcast_page"
                            isDisabled={
                                !websiteSettings?.isPublished ||
                                type === 'podcast_page' ||
                                values?.type === 'podcast_page'
                            }
                        >
                            <FormattedMessage defaultMessage="Site Web" />
                        </Checkbox>
                        <Checkbox value="player">
                            <FormattedMessage defaultMessage="Smartplayer" />
                        </Checkbox>
                        <Checkbox
                            value="smartlink"
                            isDisabled={
                                !smartlinkSettings?.isPublished ||
                                type === 'smartlink' ||
                                values?.type === 'smartlink'
                            }
                        >
                            <FormattedMessage defaultMessage="Smartlink" />
                        </Checkbox>
                        <Checkbox
                            value="newsletter"
                            isDisabled={
                                !newsletterSettings?.activated ||
                                type === 'newsletter' ||
                                values?.type === 'newsletter'
                            }
                        >
                            <FormattedMessage defaultMessage="Newsletter" />
                        </Checkbox>
                    </CheckboxGroupController>
                </CheckboxGroupWrapper>
            </FormSection>
            <SubmitWrapper>
                <Button type="button" onPress={onClose} variant="ghost">
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
                <Button
                    type="submit"
                    isDisabled={!formState.isValid || formState.isSubmitting}
                    isLoading={formState.isSubmitting}
                >
                    <FormattedMessage defaultMessage="Ajouter" />
                </Button>
            </SubmitWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const SubmitWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
`;
const CheckboxGroupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;
const CheckboxGroupLabel = styled.span`
    font-weight: var(--fw-semibold);
`;

export default CTAForm;
