import { createTransformer } from 'mobx-utils';
import { action, computed, runInAction } from 'mobx';
import Store from './Store';
import {
    addCampaign,
    deleteCampaign,
    fetchCampaign,
    fetchCampaigns,
    updateCampaign,
    uploadCampaignFile,
} from '@/api';
import CampaignModel from '../models/CampaignModel';
import PaginationModel from '../models/PaginationModel';
import { apiCall, apiFetch } from '../components/app/decorators/api';
import dayjs from 'dayjs';

class CampaignStore extends Store {
    static observables = {
        campaigns: [],
        pagination: {},
        selection: [],
    };

    byId = createTransformer((id) => this.campaigns.find((c) => c.id === parseInt(id, 10)));
    isSelected = createTransformer((id) => !!this.selection.find((i) => id === i));
    // UI - Campaign Selection

    @computed
    get isOneCampaignSelected() {
        return !!this.campaigns.find((c) => c.isSelected);
    }

    @computed
    get areAllCampaignsSelected() {
        return this.campaigns.length > 0 && this.selectedCampaigns.length === this.campaigns.length;
    }

    @computed
    get selectedCampaigns() {
        return this.campaigns.filter((c) => c.isSelected);
    }

    @action.bound
    toggleAllCampaigns() {
        const selector = !this.areAllCampaignsSelected;
        this.campaigns.map((c) => c.setSelection(selector));
    }

    // UI - Campaign podcast selection

    @action
    toggleSelection(id) {
        if (this.isSelected(id)) {
            this.selection.remove(id);
        } else {
            this.selection.push(id);
        }
    }

    // API

    @apiFetch
    async fetchCampaigns(showId, search = '', page) {
        if (search.length > 0 && search.length < 3) return;

        const { data, meta } = await fetchCampaigns(showId, search, page);
        runInAction(() => {
            this.campaigns = data.map((c) => new CampaignModel(this, c));
            this.pagination = new PaginationModel(this, meta.pagination);
        });
    }

    @apiFetch
    async fetchCampaign(id) {
        const { data } = await fetchCampaign(id);
        const campaign = await this.byId(id);
        campaign.updateData(data);
        return campaign;
    }

    async uploadFile(campaign) {
        const { data } = await uploadCampaignFile(campaign.id, campaign.audioFile);
        campaign.audioFile.updateData(data);
    }

    @apiCall
    async addCampaign(showId, campaign) {
        const { data } = await addCampaign(showId, campaign.apiObject);
        runInAction(() => {
            const camp = new CampaignModel(this, data);
            this.campaigns.unshift(camp);
            camp.show.addCampaign(camp);
            this.state.routerStore.push('menu.monetize.edit', { campaignId: data.id });
        });
    }

    @apiCall
    async pauseSelection() {
        const promises = this.selectedCampaigns.map((c) =>
            this.updateCampaign(c, { state: 'draft' }),
        );
        await Promise.all(promises);
    }

    @apiCall
    async deleteSelection() {
        const promises = this.selectedCampaigns.map((campaign) => campaign.delete());
        await Promise.all(promises);
    }

    @apiCall
    async updateCampaign(campaign, formData) {
        const { data } = await updateCampaign(campaign.id, formData);
        campaign.updateData(data);
    }

    @apiCall
    async deleteCampaign(campaign) {
        await deleteCampaign(campaign.id);
        runInAction(() => {
            this.campaigns = this.campaigns.filter((c) => c.id !== campaign.id);
            campaign.show.removeCampaign(campaign);
        });
    }

    createNewCampaign(showId, intl) {
        const newCampaign = {
            show_id: showId,
            type: 'preroll',
            name: intl.formatMessage({ defaultMessage: 'Nouvelle campagne' }),
            starts_at: new Date(),
            ends_at: dayjs().add(1, 'd').toDate(),
        };

        return new CampaignModel(this, newCampaign);
    }
}

export default CampaignStore;
