import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import Button from '@ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import getSaveAndCloseSchema from '@app/organisms/EpisodeEditForm/schemas/saveAndCloseSchema';
import { getDirtyValues } from '@/helpers';
import useInternalRouteMapping from '@hooks/useInternalRouteMapping.hook';
import useSaveEpisodeFormMutation from '@app/organisms/EpisodeEditForm/helpers/useSaveEpisodeFormMutation';
import EpisodeEditFormActionsTooltipMessage from '@app/organisms/EpisodeEditForm/actions/EpisodeEditFormActionsTooltipMessage';
import ExperimentalTooltip from '@ui/atoms/ExperimentalTooltip';
import { getChaptersIdsRequiringImageDeletion } from '@app/organisms/EpisodeEditForm/helpers/getChaptersIdsRequiringImageDeletion';
import { filterFalsyValues } from '@/helpers';

const SaveAndCloseButton = () => {
    const intl = useIntl();
    const { push } = useInternalRouteMapping();
    const {
        watch,
        reset,
        formState: { dirtyFields },
    } = useFormContext();
    const saveEpisodeFormMutation = useSaveEpisodeFormMutation();

    const episodeBeingEdited = useEpisodeBeingEdited();
    const schema = getSaveAndCloseSchema(intl);
    const formData = watch();
    const isSchemaValid = schema.isValidSync(formData);
    const hasAudioFile = episodeBeingEdited.files.length > 0;
    const isValid = isSchemaValid && hasAudioFile;

    const onPress = async () => {
        // TODO: Fix non modified fields in dirtyFields caused by useFieldArray and remove filterFalsyValues
        const dirtyValues = getDirtyValues(filterFalsyValues(dirtyFields) || {}, formData);
        const chaptersIdsRequiringImageDeletion = getChaptersIdsRequiringImageDeletion(
            dirtyFields,
            formData.chapters,
        );

        const { content, options, privacy } = dirtyValues;
        const episodeData = { ...content, ...options, ...privacy };

        try {
            await saveEpisodeFormMutation.mutateAsync(
                {
                    episodeId: episodeBeingEdited.id,
                    episode: episodeData,
                    chapters: dirtyFields.chapters ? formData.chapters : null,
                    chaptersIdsRequiringImageDeletion,
                },
                {
                    onSuccess: () => {
                        reset();
                        push('menu.episodes');
                    },
                },
            );
        } catch (error) {
            // Error toast ?
        }
    };

    return (
        <ExperimentalTooltip
            isDisabled={isValid}
            content={
                <EpisodeEditFormActionsTooltipMessage
                    hasAudioFile={hasAudioFile}
                    isSchemaValid={isSchemaValid}
                />
            }
        >
            <Button
                variant="primary"
                onPress={onPress}
                isDisabled={!isValid}
                isLoading={saveEpisodeFormMutation.isLoading}
                startIcon={
                    <FontAwesomeIcon
                        icon={icon({ name: 'check', style: 'regular' })}
                        color="inherit"
                    />
                }
            >
                <FormattedMessage defaultMessage="Enregistrer et fermer" />
            </Button>
        </ExperimentalTooltip>
    );
};

export default SaveAndCloseButton;
