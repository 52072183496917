import { AnimatePresence, motion } from 'framer-motion';
import * as PropTypes from 'prop-types';

const defaultTransition = {
    duration: 0.2,
};

const Animate = ({ children, active, slideX, slideY, variants, transition }) => {
    const animate = variants || {
        exit: { opacity: 0, x: -slideX, y: -slideY, height: 0 },
        visible: { opacity: 1, x: 0, y: 0, height: 'auto' },
        hidden: { opacity: 0, x: slideX, y: slideY, height: 0 },
    };

    const transitionProps = transition || defaultTransition;

    return (
        <AnimatePresence initial={false} exitbeforeEnter>
            {active && (
                <motion.div
                    transition={transitionProps}
                    animate={active ? 'visible' : 'hidden'}
                    variants={animate}
                    initial="hidden"
                    exit="exit"
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    );
};

Animate.prototype = {
    active: PropTypes.bool,
    slideX: PropTypes.number,
    slideY: PropTypes.number,
};

Animate.defaultProps = {
    active: true,
    slideX: 0,
    slideY: 15,
};

export default Animate;
