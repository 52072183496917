import { useSpeakers } from '@app/molecules/TranscriptionEditor/TranscriptionSpeakersProvider';
import { FormattedMessage } from 'react-intl';
import { FC } from 'react';

// Regex to match a string starting with '#' followed by one or more digits
const DEFAULT_SPEAKER_NAME_REGEX = /^#\d+/;

interface TranscriptionSpeakerNameMessageProps {
    speakerId: number | null;
}

const TranscriptionSpeakerNameMessage: FC<TranscriptionSpeakerNameMessageProps> = ({
    speakerId,
}) => {
    const { speakers } = useSpeakers();

    if (speakerId === undefined || speakerId === null) {
        return <FormattedMessage defaultMessage="Aucun orateur" />;
    }

    const speakerName = speakers.get(speakerId);

    if (!speakerName) {
        return <FormattedMessage defaultMessage="Aucun orateur" />;
    }

    if (DEFAULT_SPEAKER_NAME_REGEX.test(speakerName)) {
        return <FormattedMessage defaultMessage="Orateur {speakerName}" values={{ speakerName }} />;
    }

    return <>{speakerName}</>;
};

export default TranscriptionSpeakerNameMessage;
