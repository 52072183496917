import { useMutation, useQueryClient } from '@tanstack/react-query';
import { experimentalUploadEpisodeAudioFile } from '@/api';
import episodeKeys from '@/queries/episode/episodeKeys';
import camelcaseKeys from 'camelcase-keys';
import { sendUploadReport } from '@/api/statsUpload';

export interface MutationFnProps {
    episodeId: string;
    audioFile: File;
}

const mutationFn = async ({ episodeId, audioFile }: MutationFnProps) => {
    const uploadStartTime = performance.now();
    const { data } = await experimentalUploadEpisodeAudioFile(episodeId, audioFile);
    const uploadEndTime = performance.now();

    const uploadReport = {
        fileSize: audioFile.size,
        duration: uploadEndTime - uploadStartTime,
    };

    return { ...camelcaseKeys(data, { deep: true }), uploadReport };
};

const useAddEpisodeAudioFileMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (data, { episodeId }) => {
            sendUploadReport(data.uploadReport);
            queryClient.invalidateQueries({
                queryKey: [{ entity: 'episodeTranscriptStatus', episodeId: Number(episodeId) }],
            });
            queryClient.invalidateQueries({
                queryKey: episodeKeys.detailById(episodeId),
            });
        },
    });
};

export default useAddEpisodeAudioFileMutation;
