import Model from './Model';

class RssInfoModel extends Model {
    static attributes = {
        showTitle: 'show_title',
        episodeCount: 'episode_count',
        image: 'image',
        url: 'url',
    };
}

export default RssInfoModel;
