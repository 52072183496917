import styled from 'styled-components';
import SparklesIcon from '@public/icons/sparkles-nebula.svg';
import Button from '@/components/Button';
import { useState } from 'react';
import EpisodeAIBubblePopover from './EpisodeAIBubblePopover';
import { ENABLE_EPISODE_AI } from '@/utils/constants';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { authorizeEpisodeAIForUser } from '@/helpers';

const EpisodeAIBubble = () => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const user = useUserQuery();

    if (!ENABLE_EPISODE_AI && !authorizeEpisodeAIForUser(user.data?.id)) return null;

    return (
        <>
            {!isPopoverOpen && (
                <AIButton
                    startIcon={<Sparkles />}
                    onPress={() => setIsPopoverOpen(true)}
                    data-testid="episode-ai-bubble-button"
                />
            )}
            <EpisodeAIBubblePopover isOpen={isPopoverOpen} onOpenChange={setIsPopoverOpen} />
        </>
    );
};

const AIButton = styled(Button)`
    position: fixed;
    bottom: 5rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    background: var(--gradient-aurora);
    border-radius: var(--r-full);
    z-index: 100;

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;

const Sparkles = styled(SparklesIcon)`
    width: 1.5rem;
    height: 1.5rem;
`;

export default EpisodeAIBubble;
