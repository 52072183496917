import { action, computed, runInAction } from 'mobx';
import { FormattedMessage } from 'react-intl';
import Store from './Store';
import { apiCall, apiFetch } from '../components/app/decorators/api';
import PricesQuotaModel from '../models/PricesQuotaModel';
import NewsletterQuotaModel from '../models/NewsletterQuotaModel';
import {
    fetchNewsletterQuota,
    updateNewsletterQuota,
    fetchNewsletterQuotaPrice,
    addShowPricesQuota,
    updateShowQuota,
} from '@/api';
import { modalToastQueue } from '@/components/ui/molecules/GlobalToastRegions';
import { QUOTA_TYPES, SHOW_QUOTA_TYPES, UI_VARIANTS } from '@/utils/constants';

class QuotaStore extends Store {
    static observables = {
        pricesQuota: {},
        newQuota: 0,
        newsletterQuota: {},
    };

    @apiFetch
    async fetchNewsletterQuota() {
        const { data } = await fetchNewsletterQuota();
        runInAction(() => {
            this.newsletterQuota = new NewsletterQuotaModel(this, data);
        });
    }

    @apiCall
    async addPricesQuota(type, quantity) {
        const addPriceQuota =
            type === QUOTA_TYPES.NEWSLETTER ? fetchNewsletterQuotaPrice : addShowPricesQuota;
        const { data } = await addPriceQuota({ quantity });
        runInAction(() => {
            this.pricesQuota = new PricesQuotaModel(this, data);
        });
    }

    @apiCall
    async updateQuota(type, quantity) {
        try {
            const updateQuota =
                type === QUOTA_TYPES.NEWSLETTER ? updateNewsletterQuota : updateShowQuota;
            /**
             * Floor the quantity to the nearest 1000 for newsletters
             */
            const roundedQuantity =
                type === QUOTA_TYPES.NEWSLETTER ? Math.floor(quantity / 1000) * 1000 : quantity;
            const { data } = await updateQuota({ quantity: roundedQuantity });
            await this.state.subscriptionStore.fetchSubscription();

            if (type === QUOTA_TYPES.NEWSLETTER) {
                runInAction(() => {
                    this.newsletterQuota = new NewsletterQuotaModel(this, data);
                });
            }

            switch (type) {
                case QUOTA_TYPES.NEWSLETTER:
                    return modalToastQueue.add(
                        <FormattedMessage defaultMessage="Votre quota d'emails a été mis à jour" />,
                        {
                            variant: UI_VARIANTS.SUCCESS,
                            timeout: 5000,
                        },
                    );
                case SHOW_QUOTA_TYPES.ACTIVE_SHOWS:
                    return modalToastQueue.add(
                        <FormattedMessage defaultMessage="Votre quota d'émissions a été mis à jour" />,
                        {
                            variant: UI_VARIANTS.SUCCESS,
                            timeout: 5000,
                        },
                    );
                default:
                    return modalToastQueue.add(
                        <FormattedMessage defaultMessage="Votre quota a été mis à jour" />,
                        {
                            variant: UI_VARIANTS.SUCCESS,
                            timeout: 5000,
                        },
                    );
            }
        } catch (e) {
            return modalToastQueue.add(
                <FormattedMessage defaultMessage="Votre quota n'a pu être mis à jour" />,
                {
                    variant: UI_VARIANTS.ALERT,
                    timeout: 5000,
                },
            );
        }
    }

    @computed
    get getQuotaType() {
        const percentage = (totalItem, maxItem) => {
            if (totalItem > maxItem) return 100;
            return Math.floor((totalItem / maxItem) * 100);
        };
        // Fix to remove later
        const replaceNumber = (total, max) => (total > max ? max : total);

        // ACTIVE_SHOWS
        const totalShowsActive = this.state.showStore.statusAllShows.activeShows.totalShowsActive;
        const activeShowsMax = this.state.subscriptionStore.subscription?.showsMax;
        // ARCHIVED_SHOWS
        const totalShowsArchived =
            this.state.showStore.statusAllShows.archivedShows.totalShowsArchived;
        const archivedShowsMax = this.state.subscriptionStore.subscription?.archivedShowsMax;

        return {
            [SHOW_QUOTA_TYPES.ACTIVE_SHOWS]: {
                total: replaceNumber(totalShowsActive, activeShowsMax),
                maxQuota: activeShowsMax,
                percentage: percentage(totalShowsActive, activeShowsMax),
            },
            [SHOW_QUOTA_TYPES.ARCHIVED_SHOWS]: {
                total: totalShowsArchived,
                maxQuota: archivedShowsMax,
                percentage: percentage(totalShowsArchived, archivedShowsMax),
            },
            [QUOTA_TYPES.NEWSLETTER]: {
                total: this.newsletterQuota.total,
                maxQuota: this.newsletterQuota.maximum,
                percentage: percentage(this.newsletterQuota.total, this.newsletterQuota.maximum),
            },
        };
    }

    @computed
    get getQuotaRange() {
        const percent = Math.round(
            (this.newsletterQuota.total / this.newsletterQuota.maximum) * 100,
        );
        return {
            percent,
            warningPercent: percent > 75 && percent < 100,
        };
    }

    @action
    setNewQuota(currentQuota) {
        this.newQuota = currentQuota;
    }
}

export default QuotaStore;
