import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import moreIcon from '@public/icons/add.svg';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import useDroppable from '../../../utils/hooks/useDroppable';
import Animate from '../../ui/molecules/Animate';
import useGetBoundingClientRect from '../../../utils/hooks/useGetBoundingClientRect';
import DeprecatedWhitePaper from '../../ui/atoms/DeprecatedWhitePaper';
import { ACTIONS_CTA, ICON_LIST_CTA, LINKS_NAMES } from './CTAAccessAllowed';
import { useResponsive } from '../../../utils/hooks/useResponsive';
import useCallToActionButtonsQuery from '@/queries/callToAction/useCallToActionButtonsQuery.hook';

const BUTTON_HEIGHT = 40;

const CTAMenuDropDown = ({ dispatch, ctaList, defaultDropDown }) => {
    const { showId } = useParams();
    const callToActionButtons = useCallToActionButtonsQuery({ showId });

    const [ref, isOpen, opened, closeDropDownCTA] = useDroppable(false);
    const [boundingDropDown, refDropDown] = useGetBoundingClientRect();
    const [boundingAddButton, refAddButton] = useGetBoundingClientRect();
    const { isMobileOrTablet } = useResponsive();

    if (callToActionButtons.isLoading || callToActionButtons.isError) {
        return null;
    }

    const currentCtaTypes = callToActionButtons.data.map((cta) => cta.type);
    const ALL_CTAS_AVAILABLE = [...ctaList, ...callToActionButtons.data];
    const defaultFields = [
        LINKS_NAMES.PAGE_PODCAST,
        LINKS_NAMES.SMARTLINK,
        LINKS_NAMES.NEWSLETTER,
        LINKS_NAMES.CROWDFUNDING,
    ];

    return (
        <DeprecatedPaper
            ref={ref}
            position="relative"
            w={isMobileOrTablet ? '100%' : 'max-content'}
        >
            <Animate active={isOpen}>
                <DeprecatedPaper
                    flex
                    rounded
                    raise="smooth"
                    column
                    maxW="max-content"
                    minW={240}
                    background="white"
                    ref={refDropDown}
                    x={defaultDropDown ? 0 : boundingAddButton.width + 10}
                    y={defaultDropDown ? BUTTON_HEIGHT : BUTTON_HEIGHT - boundingDropDown.height}
                    position="absolute"
                    overflowHide
                >
                    <DeprecatedText color="--neutral500" size="small" px={12} pt={12}>
                        <FormattedMessage defaultMessage="Accès direct" />
                    </DeprecatedText>
                    {ALL_CTAS_AVAILABLE.map(
                        (item) =>
                            item.url &&
                            !currentCtaTypes.includes(item.type) && (
                                <DeprecatedPaper
                                    flex
                                    align="center"
                                    gap={12}
                                    px={12}
                                    minH={48}
                                    key={item.id}
                                    hover="--neutral50"
                                    cssAnim
                                    onClick={() =>
                                        dispatch({
                                            type: defaultFields.includes(item.type)
                                                ? ACTIONS_CTA.OPEN_CREATE_BUTTON_MODAL_CTA
                                                : ACTIONS_CTA.OPEN_EDIT_MODAL_CTA,
                                            payload: { closeDropDownCTA, item: { ...item } },
                                        })
                                    }
                                >
                                    {ICON_LIST_CTA[item.type]}
                                    <DeprecatedPaper overflowHide>
                                        <DeprecatedText>{item.text}</DeprecatedText>
                                        <DeprecatedText color="--neutral500" size="small" ellipsis>
                                            {item.url}
                                        </DeprecatedText>
                                    </DeprecatedPaper>
                                </DeprecatedPaper>
                            ),
                    )}

                    <DeprecatedWhitePaper
                        rounded={false}
                        bt={1}
                        flex
                        gap={12}
                        px={12}
                        minH={44}
                        align="center"
                        hover="--primary100"
                        onClick={() =>
                            dispatch({
                                type: ACTIONS_CTA.OPEN_CREATE_BUTTON_MODAL_CTA,
                                payload: { closeDropDownCTA, item: { type: 'custom' } },
                            })
                        }
                    >
                        <DeprecatedIcon as={moreIcon} color="--primary" />
                        <DeprecatedText color="--primary" weight="semibold">
                            <FormattedMessage defaultMessage="Nouveau bouton" />
                        </DeprecatedText>
                    </DeprecatedWhitePaper>
                </DeprecatedPaper>
            </Animate>
            <DeprecatedButton
                fullWidth={isMobileOrTablet}
                ref={refAddButton}
                onClick={() => {
                    dispatch({ type: ACTIONS_CTA.OPEN_MENU_DROP_DOWN_CTA, payload: { opened } });
                }}
            >
                <DeprecatedIcon as={moreIcon} />
                <FormattedMessage defaultMessage="Créer un bouton personnalisé" />
            </DeprecatedButton>
        </DeprecatedPaper>
    );
};

export default CTAMenuDropDown;
