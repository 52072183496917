import { action, computed, runInAction } from 'mobx';
import Store from '../Store';
import { apiCall, apiFetch } from '../../components/app/decorators/api';
import {
    fetchCampaignOverallStats,
    fetchCampaignStats,
    fetchCampaignStatsCsv,
    fetchEpisodesCompare,
    fetchEpisodesRankingFromCampaign,
    fetchEpisodesRankingFromShow,
    fetchEpisodesTopRankingFromCampaign,
    fetchEpisodesTopRankingFromShow,
    fetchShowOverallStats,
    fetchShowsSingleListener,
    fetchShowsSingleListenerCsv,
    fetchShowStats,
    fetchShowStatsCsv,
} from '@/api';
import StatsVariationModel from '../../models/StatsVariationModel';
import { download, makeArray, parseIntArray } from '../../helpers';
import OverallStatsModel from '../../models/OverallStatsModel';
import PodcastRankModel from '../../models/PodcastRankModel';
import PaginationModel from '../../models/PaginationModel';
import PodcastRankPercentModel from '../../models/PodcastRankPercentModel';
import EpisodeCompareStatsModel from '../../models/EpisodeCompareStatsModel';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);

class ListenersStatsStore extends Store {
    static observables = {
        // LISTENERS
        variations: [],
        overall: null,
        // SINGLE LISTENERS
        singleListenersStats: [],
        // RANKING
        podcastRanks: [],
        podcastRanksPagination: {},
        podcastRanksPercent: [],
        // COMPARE EPISODES
        episodesCompareStats: [],
        daysRangeCompareEpisode: 7,
        // UI STATE
        stepMobile: '',
    };

    // GETTER GRAPHICS LISTENER
    @action.bound
    resetVariations() {
        this.variations.mapData = [];
    }

    @computed
    get maxPodcastDownload() {
        const maxDownloads = this.variations.mapData?.map((c) => c.downloads);
        return maxDownloads && Math.max(...maxDownloads);
    }

    @computed
    get totalListening() {
        return this.variations.sumDownloads || 0;
    }

    @computed
    get episodesShrinkedPublishedAt() {
        return this.state.podcastStore.podcastsShrinked
            .map(
                (episode) =>
                    episode.publishedAt && episode.status === 'active' && episode.publishedAt,
            )
            .filter((clean) => clean);
    }

    @computed
    get variationsFormatted() {
        // We can't inject intl to this place, so we translate x coordinate manually
        const languageCode = this.state.userStore.user?.languageCode;
        const translateRangeDays = {
            fr: 'Semaine du',
            en: 'Week of',
        };

        const formatWeeklyDate = (date) => {
            if (languageCode === 'fr') {
                return date.startOf('week').format('DD/MM');
            }

            return date.startOf('week').format('M/D');
        };

        return (
            this.variations.mapData?.map((data, id) => {
                const date = dayjs(data.timestamp);
                return {
                    id,
                    downloads: data.downloads,
                    variation: data.variation === null ? '-' : data.variation,
                    days: languageCode === 'fr' ? date.format('DD/MM/YYYY') : date.format('M/D/YY'),
                    weeks: `${translateRangeDays[languageCode]} ${formatWeeklyDate(date)}`,
                    months: date.format('MMMM YYYY'),
                    years: date.format('YYYY'),
                    timestamp: data.timestamp,
                    translateWeeksXCoordinate: translateRangeDays[languageCode],
                };
            }) || []
        );
    }

    // UI EPISODE COMPARE
    @action.bound
    setDaysRangeEpisodesCompareStats(days) {
        this.daysRangeCompareEpisode = days;
    }

    // GETTER EPISODES COMPARE
    @computed
    get getEpisodeCompareStatsFormatted() {
        const daysRange = this.daysRangeCompareEpisode;
        let episodesListCompareStats = [];

        const episodesDataCompareStats = makeArray(daysRange, 1).map((day, index) => {
            const data = this.episodesCompareStats.reduce((allEpisodes, episode) => {
                if (!episodesListCompareStats.find((ep) => ep.id === parseInt(episode.id, 10)))
                    episodesListCompareStats = [
                        ...episodesListCompareStats,
                        { id: parseInt(episode.id, 10), name: episode.name },
                    ];

                return {
                    ...allEpisodes,
                    day,
                    index,
                    [episode.id]: episode.data[index]?.downloads,
                };
            }, {});
            return {
                ...data,
            };
        });

        return {
            episodesDataCompareStats,
            episodesListCompareStats,
            episodesListCompareStatsHasData: episodesListCompareStats.length > 0,
        };
    }

    // FETCH
    // HEADER LISTENING
    @apiFetch
    async fetchOverallStats(showId, itemType, itemId) {
        let response;
        switch (itemType) {
            case 'campaign':
                response = await fetchCampaignOverallStats(itemId, { timezone: this.timezone });
                break;
            default:
                response = await fetchShowOverallStats(showId, { timezone: this.timezone });
        }

        const { data } = response;
        runInAction(() => {
            this.overall = new OverallStatsModel(this, data);
        });
    }

    // GRAPHICS LISTENING
    @apiFetch
    async fetchStats(showId, itemType, itemId, optionsType, query) {
        const { podcasts, ...rest } = query;

        let response;
        switch (itemType || optionsType) {
            case 'campaign':
                response = await fetchCampaignStats(itemId, { ...query, timezone: this.timezone });
                break;
            case 'singleListener':
                response = await fetchShowsSingleListener(showId, {
                    ...query,
                    timezone: this.timezone,
                });
                break;
            default:
                response = await fetchShowStats(showId, {
                    ...rest,
                    podcasts: parseIntArray(podcasts),
                    timezone: this.timezone,
                });
        }

        const { data, meta } = response;
        runInAction(() => {
            this.variations = new StatsVariationModel(this, data);
            this.stepMobile = meta.step;
        });
    }

    // GRAPHICS COMPARE EPISODES
    @apiFetch
    async fetchEpisodesCompare(showId, query) {
        const { days, podcasts } = query;

        try {
            const { data } = await fetchEpisodesCompare(showId, {
                days,
                podcasts: parseIntArray(podcasts),
            });
            runInAction(() => {
                this.episodesCompareStats = Object.keys(data).map((id) => {
                    const episodesCompareStatsModel = {
                        id,
                        name: data[id].name,
                        data: data[id].data,
                    };
                    return new EpisodeCompareStatsModel(this, episodesCompareStatsModel);
                });
            });
        } catch (e) {
            this.state.routerStore.push('stats.show', { tab: 'listeners' });
        }
    }

    // PIE GRAPHICS
    @apiFetch
    async fetchEpisodesTopRanking(showId, itemType, itemId, query) {
        const { podcasts, ...rest } = query;
        let response;
        switch (itemType) {
            case 'campaign':
                response = await fetchEpisodesTopRankingFromCampaign(itemId, {
                    ...query,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
                break;
            default:
                response = await fetchEpisodesTopRankingFromShow(showId, {
                    ...rest,
                    podcasts: parseIntArray(podcasts),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
        }

        const { data } = response;
        runInAction(() => {
            this.podcastRanksPercent = data.map((rank) => new PodcastRankPercentModel(this, rank));
        });
    }

    // RANKING TABLE
    @apiFetch
    async fetchEpisodesRanking(showId, itemType, itemId, query) {
        const { podcasts, ...rest } = query;

        let response;
        switch (itemType) {
            case 'campaign':
                response = await fetchEpisodesRankingFromCampaign(itemId, {
                    ...query,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
                break;
            default:
                response = await fetchEpisodesRankingFromShow(showId, {
                    ...rest,
                    podcasts: parseIntArray(podcasts),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
        }

        const { data, pagination } = response;
        runInAction(() => {
            this.podcastRanks = data.map((rank) => new PodcastRankModel(this, rank));
            this.podcastRanksPagination = new PaginationModel(this, pagination);
        });
    }

    @apiCall
    async downloadStatsCsv(showId, itemType, itemId, optionsType, query) {
        const { podcasts, ...rest } = query;

        let response;
        switch (itemType || optionsType) {
            case 'campaign':
                response = await fetchCampaignStatsCsv(itemId, {
                    ...query,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
                break;
            case 'singleListener':
                response = await fetchShowsSingleListenerCsv(showId, {
                    ...query,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
                break;
            default:
                response = await fetchShowStatsCsv(showId, {
                    ...rest,
                    podcasts: parseIntArray(podcasts),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
        }

        const { axios, ...data } = response;
        const filename = axios.headers['content-disposition'].match(/filename="(.+)"/)[1];
        download(Object.values(data).join(''), filename, 'txt');
    }
}

export default ListenersStatsStore;
