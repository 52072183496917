import axios, { AxiosError, AxiosInstance } from 'axios';
import Cookie from 'js-cookie';
import { runInAction } from 'mobx';
import stores from '@/stores/RootStore';
import { buildQuery } from './query';

type Api = keyof typeof apiLookup;
const instances: Record<Api, AxiosInstance> = {} as Record<Api, AxiosInstance>;
let isRefreshing = false;
const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain',
};
const apiLookup = {
    AUTH: process.env.API_AUTH,
    CONTENT: process.env.API_CONTENT,
    BILLING: process.env.API_BILLING,
    PUBLIC: process.env.API_PUBLIC,
    ANALYTICS: process.env.API_ANALYTICS,
    PSO: process.env.API_PSO,
};

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
type Query = Record<string, any>;

interface CallApiProps {
    method?: Method;
    target: string;
    body?: any;
    query?: Query;
    [key: string]: any;
}

export const createApi = (api: Api) => {
    return async function callApi({ method, target, body, query, ...rest }: CallApiProps) {
        const axiosInstance = getAxiosInstance(api);
        const { url, parsedMethod } = await parseApiUrl({ api, method, target, query });
        const config = { url, method: method ?? parsedMethod ?? 'GET', data: body, ...rest };

        try {
            const res = await axiosInstance.request(config);
            return { ...res.data, axios: res };
        } catch (error) {
            const typedError = error as AxiosError;
            if (!isRefreshing && typedError.response && typedError.response.status === 401) {
                return refreshToken(axiosInstance, config);
            }
            throw error;
        }
    };
};

interface parseApiUrlProps {
    api: keyof typeof apiLookup;
    target: string;
    query?: Query;
    method?: Method;
}

export async function parseApiUrl({
    api,
    target,
    query,
}: parseApiUrlProps): Promise<{ url: string; parsedMethod?: Method }> {
    return { url: `${apiLookup[api]}${target}${buildQuery(query)}` };
}

export function getAxiosInstance(apiName: Api) {
    if (!(apiName in instances)) {
        instances[apiName] = axios.create({ baseURL: apiLookup[apiName], headers });
    }
    return instances[apiName];
}

export const getAxiosInstances = () => instances;

async function refreshToken(axiosInstance: AxiosInstance, config: any) {
    isRefreshing = true;
    try {
        const { data } = await getAxiosInstance('CONTENT').request({
            url: '/refresh',
            method: 'POST',
            data: { refresh_token: Cookie.get('refresh') },
        });
        runInAction(() => {
            stores.authStore.refreshToken = data.refresh_token;
            stores.authStore.token = data.access_token;
        });

        const res = await axiosInstance.request(config);
        isRefreshing = false;
        return { ...res.data, axios: res };
    } catch (error) {
        isRefreshing = false;
        stores.authStore.token = null;
    }

    return undefined;
}
