import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCallToActionButton } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import callToActionKeys from './callToActionKeys';

export interface MutationFnProps {
    ctaId?: string;
}

const mutationFn = async ({ ctaId }: MutationFnProps) => {
    const { data } = await deleteCallToActionButton(ctaId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

export default function useDeleteCallToActionButtonMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: callToActionKeys.all(),
            });
        },
    });
}
