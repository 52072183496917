import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTextField } from 'react-aria';
import IconCheckedCircle from '@ui/icons/IconCheckedCircle';
import IconError from '@ui/icons/IconError';
import IconCopy from '../../icons/IconCopy';
import IconButton from '../IconButton';
import { FormattedMessage, useIntl } from 'react-intl';
import { motion, AnimatePresence } from 'framer-motion';
import Text from '../Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ErrorMessage from '../ErrorMessage';
import styled, { css } from 'styled-components';

const InputText = (props) => {
    const intl = useIntl();
    let { label, min, max } = props;
    let ref = useRef();
    let { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, ref);
    const isValid = props.inputState === 'valid';
    const hasErrors = props.inputState === 'errors';
    const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);
    const prefixRef = useRef(null);
    const withIcon =
        isValid || hasErrors || props.copyToClipboardEnabled || props.emptyButtonEnabled;
    const prefixWidth = prefixRef.current?.getBoundingClientRect().width;

    const handleCopyToClipboard = async () => {
        const value = ref.current?.value;
        const copiedValue = props.prefix
            ? `${props.prefix}${props.prefix.endsWith('/') ? '' : '/'}${value}`
            : value;
        await navigator.clipboard.writeText(copiedValue);
        setHasCopiedToClipboard(true);
        props.onCopyToClipboard();
    };

    return (
        <InputWrapper className={props.className}>
            {props.label && (
                <OuterLabelWrapper>
                    <LabelWrapper>
                        <LabelTooltipWrapper>
                            <Label isRequired={props.isRequired} {...labelProps}>
                                {label}
                                {props.isRequired && (
                                    <Text as="span" fontWeight="--fw-semibold" color="--alert500">
                                        *
                                    </Text>
                                )}
                                {props.isOptional && (
                                    <OptionalLabel>
                                        <FormattedMessage defaultMessage="(facultatif)" />
                                    </OptionalLabel>
                                )}
                            </Label>
                            {props.tooltip}
                        </LabelTooltipWrapper>
                        {props.description && (
                            <Description {...descriptionProps}>{props.description}</Description>
                        )}
                    </LabelWrapper>
                    {props.maxLength && (
                        <CharacterCount hasOverflowed={props.value?.length > props.maxLength}>
                            {props.value?.length || 0}/{props.maxLength}
                        </CharacterCount>
                    )}
                    {props.customButton}
                </OuterLabelWrapper>
            )}
            <InputInnerWrapper>
                {props.inputIcon && (
                    <InputIconWrapper backgroundColor={props.inputIconBackgroundColor}>
                        {props.inputIcon}
                    </InputIconWrapper>
                )}
                {props.prefix && (
                    <InputPrefixWrapper ref={prefixRef}>
                        <Text numberOfLines={1} style={{ maxWidth: '300px' }} color="--neutral500">
                            {props.prefix}
                        </Text>
                    </InputPrefixWrapper>
                )}
                <Input
                    ref={ref}
                    {...inputProps}
                    minLength={props.characterOverflowBehavior === 'error' ? null : props.minLength}
                    maxLength={props.characterOverflowBehavior === 'error' ? null : props.maxLength}
                    as={props.elementType}
                    minHeight={props.minHeight}
                    isValid={isValid}
                    hasErrors={hasErrors}
                    hasIcon={!!props.inputIcon}
                    onChange={(event) => props.onChange(event.target.value)}
                    onKeyUp={(event) => props.onKeyUp(event)}
                    onKeyDown={(event) => props.onKeyDown(event)}
                    onFocus={(event) => props.onFocus(event)}
                    onBlur={(event) => props.onBlur(event)}
                    onFocusChange={(event) => props.onFocusChange(event)}
                    onCopy={(event) => {
                        props.onCopy(event);
                        setHasCopiedToClipboard(true);
                    }}
                    onCut={(event) => props.onCut(event)}
                    onPaste={(event) => props.onPaste(event)}
                    onCompositionStart={(event) => props.onCompositionStart(event)}
                    onCompositionEnd={(event) => props.onCompositionEnd(event)}
                    onCompositionUpdate={(event) => props.onCompositionUpdate(event)}
                    onSelect={(event) => props.onSelect(event)}
                    onBeforeInput={(event) => props.onBeforeInput(event)}
                    onInput={(event) => props.onInput(event)}
                    onCopyToClipboard={(event) => props.onCopyToClipboard(event)}
                    prefixWidth={prefixWidth}
                    uppercase={props.uppercase}
                    min={min}
                    max={max}
                />

                <AnimatePresence>
                    {hasCopiedToClipboard && (
                        <CopiedToClipboard
                            initial={{ opacity: 1 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ delay: 1 }}
                            onAnimationComplete={() => setHasCopiedToClipboard(false)}
                        >
                            <FormattedMessage defaultMessage="Copié ! 👍" />
                        </CopiedToClipboard>
                    )}
                </AnimatePresence>

                {withIcon && (
                    <IconWrapper>
                        {isValid ? (
                            <IconValid />
                        ) : hasErrors ? (
                            <IconInvalid />
                        ) : props.copyToClipboardEnabled ? (
                            <CopyIconButton
                                onPress={handleCopyToClipboard}
                                icon={<IconCopyToClipboard />}
                                aria-label={intl.formatMessage({
                                    defaultMessage: 'Copier dans le presse-papier',
                                })}
                            />
                        ) : props.emptyButtonEnabled ? (
                            <EmptyInputButton
                                onPress={() => props.onChange('')}
                                icon={
                                    <IconClose
                                        icon={icon({ name: 'circle-xmark', style: 'solid' })}
                                    />
                                }
                                aria-label={intl.formatMessage({
                                    defaultMessage: 'Effacer le champ',
                                })}
                            />
                        ) : null}
                    </IconWrapper>
                )}
            </InputInnerWrapper>
            {props.errorMessage && (
                <ErrorMessage {...errorMessageProps}>{props.errorMessage}</ErrorMessage>
            )}
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 100%;
`;
const OuterLabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const CharacterCount = styled.span`
    font-size: 12px;
    color: ${(p) => (p.hasOverflowed ? 'var(--alert)' : 'var(--neutral500)')};
    font-weight: var(--fw-semibold);
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const LabelTooltipWrapper = styled.div`
    display: flex;
    column-gap: 0.25rem;
    align-items: center;
    width: 100%;
`;

const Label = styled.label`
    font-size: var(--fs-body);
    font-weight: var(--fw-semibold);
    ${(p) =>
        p.isRequired &&
        css`
            display: flex;
            justify-content: start;
            align-items: start;
            column-gap: 0.25rem;
        `}
`;

const Description = styled.div`
    font-size: var(--fs-body);
    color: var(--neutral500);
`;

const InputInnerWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const InputPrefixWrapper = styled.div`
    position: absolute;
    left: 0.25rem;
    top: 0.25rem;
    height: calc(100% - 0.5rem);
    padding: 0.375rem 0.5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-xs);
    max-width: 150px;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        max-width: 300px;
    }
`;

const InputIconWrapper = styled.div`
    position: absolute;
    left: 0.25rem;
    top: 0.25rem;
    height: calc(100% - 0.5rem);
    width: calc(40px - 0.5rem);
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.backgroundColor ?? 'var(--neutral50)'};
    border-radius: var(--r-xs);

    & svg {
        display: block;
    }
`;

const Input = styled.input`
    transition-duration: 0.2s;
    width: 100%;
    height: 40px;
    border-radius: var(--r-s);
    border: 1px solid var(--neutral200);
    padding-block: 10px;
    padding-inline: 12px;

    &:hover {
        transition-duration: 0.2s;
        border-color: var(--neutral400);
    }

    &:focus {
        transition-duration: 0.2s;
        border-color: var(--primary);
        box-shadow: var(--s-primary-light);
    }

    &:read-only {
        background: var(--neutral50);
        border-color: var(--neutral200);
    }

    &:disabled {
        cursor: not-allowed;
        color: var(--neutral500);
        background: var(--white);
    }

    ${(p) =>
        p.isValid &&
        css`
            padding-right: 2.5rem;
            border-color: var(--success);
        `}

    ${(p) =>
        p.hasErrors &&
        css`
            padding-right: 2.5rem;
            border-color: var(--alert);
        `}

    ${(p) =>
        p.hasIcon &&
        css`
            padding-left: 2.5rem;
        `}

    ${(p) =>
        p.prefixWidth &&
        css`
            padding-left: calc(${p.prefixWidth}px + 0.75rem);
        `}

    ${(p) =>
        p.as === 'textarea' &&
        p.minHeight &&
        css`
            min-height: ${p.minHeight};
        `}

        ${({ uppercase }) =>
        uppercase &&
        css`
            text-transform: uppercase;
        `}
`;

const OptionalLabel = styled.span`
    padding-left: 0.25rem;
    font-size: var(--fs-body-m);
    font-weight: var(--fw-normal);
    color: var(--neutral500);
`;

const CopiedToClipboard = styled(motion.span)`
    display: inline-flex;
    align-items: center;
    padding-inline: 1rem;
    position: absolute;
    left: 1px;
    top: 1px;
    border-radius: var(--r-xs);
    background: white;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
`;

const IconWrapper = styled.div`
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 30px;
    padding-inline: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        display: block;
    }
`;

const IconValid = styled(IconCheckedCircle)`
    color: var(--success);
`;

const IconInvalid = styled(IconError)`
    color: var(--alert);
`;

const CopyIconButton = styled(IconButton)`
    display: block;
    padding: 0;
    background: none;
    box-shadow: none;
`;
const IconCopyToClipboard = styled(IconCopy)`
    color: var(--neutral500);
`;
const EmptyInputButton = styled(IconButton)`
    display: block;
    padding: 0;
    background: none;
    box-shadow: none;
    font-size: 1rem;
`;
const IconClose = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;

InputText.propTypes = {
    elementType: PropTypes.oneOf(['input', 'textarea']),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    inputState: PropTypes.oneOf(['pristine', 'touched', 'valid', 'errors']),
    isRequired: PropTypes.bool,
    pattern: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    errorMessage: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    id: PropTypes.string,
    excludeFromTabOrder: PropTypes.bool,
    autoComplete: PropTypes.oneOf(['on', 'off']),
    name: PropTypes.string,
    type: PropTypes.oneOf(['text', 'search', 'url', 'tel', 'email', 'password']),
    inputMode: PropTypes.oneOf([
        'none',
        'text',
        'search',
        'url',
        'tel',
        'email',
        'numeric',
        'decimal',
    ]),
    characterOverflowBehavior: PropTypes.oneOf(['block', 'error']),
    copyToClipboardEnabled: PropTypes.bool,
    emptyButtonEnabled: PropTypes.bool,
    inputIcon: PropTypes.node,
    inputIconBackgroundColor: PropTypes.string,
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onFocusChange: PropTypes.func,
    onCopy: PropTypes.func,
    onCut: PropTypes.func,
    onPaste: PropTypes.func,
    onCompositionStart: PropTypes.func,
    onCompositionEnd: PropTypes.func,
    onCompositionUpdate: PropTypes.func,
    onSelect: PropTypes.func,
    onBeforeInput: PropTypes.func,
    onInput: PropTypes.func,
    onCopyToClipboard: PropTypes.func,
    tooltip: PropTypes.element,
    isOptional: PropTypes.bool,
    minHeight: PropTypes.string,
    prefix: PropTypes.string,
    className: PropTypes.string,
    customButton: PropTypes.node,
    uppercase: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
};

InputText.defaultProps = {
    elementType: 'input',
    label: '',
    description: '',
    isDisabled: false,
    isReadOnly: false,
    inputState: null,
    isRequired: false,
    isOptional: false,
    minLength: null,
    maxLength: null,
    errorMessage: '',
    autoFocus: false,
    placeholder: '',
    value: '',
    defaultValue: '',
    id: crypto.randomUUID(),
    excludeFromTabOrder: false,
    autoComplete: 'on',
    name: crypto.randomUUID(),
    type: 'text',
    inputMode: 'text',
    characterOverflowBehavior: 'error',
    copyToClipboardEnabled: false,
    emptyButtonEnabled: false,
    inputIcon: null,
    uppercase: false,
    onChange: () => {},
    onKeyUp: () => {},
    onKeyDown: () => {},
    onFocus: () => {},
    onBlur: () => {},
    onFocusChange: () => {},
    onCopy: () => {},
    onCut: () => {},
    onPaste: () => {},
    onCompositionStart: () => {},
    onCompositionEnd: () => {},
    onCompositionUpdate: () => {},
    onSelect: () => {},
    onBeforeInput: () => {},
    onInput: () => {},
    onCopyToClipboard: () => {},
};

export default InputText;
