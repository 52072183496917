import Model from './Model';

class AppleShowModel extends Model {
    static attributes = {
        name: 'show_title',
        author: 'author',
        img: 'image',
        url: 'url',
        episodeCount: 'episode_count',
    };
}

export default AppleShowModel;
