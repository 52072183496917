import { useEffect, useState } from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import EpisodeAIPanelField from './EpisodeAIPanelField';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import EpisodeAIOverwriteWarningModal from '../EpisodeAIOverwriteWarningModal';
import { useParams } from 'react-router';
import useEpisodeNewsletterTemplateQuery from '@/queries/newsletter/useEpisodeNewsletterTemplateQuery.hook';
import useShowNewsletterTemplateQuery from '@/queries/newsletter/useShowNewsletterTemplateQuery.hook';
import useAINewsletterSuggestionsQuery from '@queries/ai/useAINewsletterSuggestionQuery.hook';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import { useEpisodeAISettings } from '@/components/EpisodeAI/contexts/EpisodeAISettingsContext';

interface EpisodeAINewsletterFieldProps {
    generateButtonDataTestId: string;
}

const EpisodeAINewsletterField = ({ generateButtonDataTestId }: EpisodeAINewsletterFieldProps) => {
    const { episodeId, showId } = useParams<{ episodeId: string; showId: string }>();
    const [settings] = useEpisodeAISettings();
    const newsletterSettings = settings.get('newsletter') || {
        customPrompt: '',
        saveAsDefault: false,
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const newsletterSuggestionQuery = useAINewsletterSuggestionsQuery({
        episodeId,
        settings: newsletterSettings,
        delay: 2000,
    });
    const [isGenerated, setIsGenerated] = useState(
        localStorage.getItem(`episode-ai-${episodeId}-generated-newsletter`) === 'true',
    );
    const [state, dispatch] = useEpisodeAILoading();
    const isLoading = state.get('newsletter') || false;
    const episodeNewsletterTemplateQuery = useEpisodeNewsletterTemplateQuery(episodeId);
    const showNewsletterTemplateQuery = useShowNewsletterTemplateQuery(showId);
    const noNewsletterSettings =
        showNewsletterTemplateQuery.isError ||
        showNewsletterTemplateQuery.data?.activated === false;
    const message = episodeNewsletterTemplateQuery.data?.messageTpl;
    const isHtmlMessageEmpty = /^(<p>(<br>)?<\/p>)+$/.test(message);
    const episode = useEpisodeQuery({ episodeId, config: { enabled: !!episodeId } });
    const isFreeTrial = isGenerated && episode.data?.aiFreeTrialEnabled;
    const isDisabled = noNewsletterSettings || isFreeTrial;

    // Once the newsletter suggestions are fetched, set the generated flag to true
    useEffect(() => {
        if (newsletterSuggestionQuery.data && !newsletterSuggestionQuery.isFetching) {
            localStorage.setItem(`episode-ai-${episodeId}-generated-newsletter`, 'true');
            setIsGenerated(true);
        }
    }, [newsletterSuggestionQuery.data, newsletterSuggestionQuery.isFetching]);

    const handleOpenModal = () => {
        if (!isHtmlMessageEmpty && message?.length > 0) {
            setIsModalOpen(true);
            return;
        }
        handleGenerate();
    };

    const handleGenerate = () => {
        if (noNewsletterSettings) return;
        setIsModalOpen(false);
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.START,
            payload: 'newsletter',
        });
        newsletterSuggestionQuery.refetch();
    };

    return (
        <>
            <EpisodeAIPanelField
                data-testid={generateButtonDataTestId}
                icon={<Icon icon={icon({ name: 'envelope-open-text', style: 'solid' })} />}
                type="newsletter"
                title={<FormattedMessage defaultMessage="Newsletter" />}
                description={
                    <FormattedMessage defaultMessage="Un e-mail prêt à envoyer pour notifier votre audience" />
                }
                onAction={handleOpenModal}
                isLoading={isLoading}
                isGenerated={isGenerated && !isLoading}
                isDisabled={isDisabled}
                tooltip={
                    isFreeTrial ? (
                        '💎 Add-on'
                    ) : (
                        <FormattedMessage defaultMessage="Activez la Newsletter de votre podcast depuis l'onglet Communication afin de notifier vos auditeurs par e-mail de la sortie de chaque nouvel épisode. 💌" />
                    )
                }
            />
            <EpisodeAIOverwriteWarningModal
                isOpen={isModalOpen}
                onOpenChange={setIsModalOpen}
                onAction={handleGenerate}
                actionTestId="episode-ai-newsletter-overwrite-modal-button"
                message={
                    <FormattedMessage defaultMessage="La génération AI écrasera le contenu actuel" />
                }
            />
        </>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--primary-magic);
`;

export default EpisodeAINewsletterField;
