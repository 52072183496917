import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Rule from '../Rule';
import { useFormContext } from 'react-hook-form';
import { usePsoScoreContext } from '@/context/PsoScoreContext';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import styled from 'styled-components';

interface DescriptionRulesProps {
    setScore: (score: number) => void;
}

const DescriptionRules = ({ setScore }: DescriptionRulesProps) => {
    const { showId }: { showId: string } = useParams();
    const { data: psoConfiguration } = usePsoQuery({ showId, enabled: true });
    const { targetKeyword } = usePsoScoreContext();
    const { watch } = useFormContext();
    const description = watch('content.htmlDescription') || '';

    const targetKeywordFoundScoreMap = [100, 75, 50, 0];
    const targetKeywordSearch = (needle: string | null, haystack: string): number => {
        const thresholds = [100, 200, 300];
        if (!needle) return -1;

        // Split the haystack into an array of words, each word casted to lowercase
        const words = haystack.split(/\s+/).map((w) => w.toLocaleLowerCase());

        let searchString = '';
        let foundAt = -1;

        /**
         * This will allow: "bliss stories" | "Bliss Stories" | "bliSs StOries"
         * And disallow: "blablaBliss Stories" | "Bliss and stories" | "Bliss storiesblabla
         */
        const needleRegex = new RegExp(`\\b${targetKeyword}\\b`, 'i');

        /**
         * For each threshold, turn a slice (0 -> threshold's length) of the words array back into a string and search for the needle.
         * As soon as we find the needle within a threshold, we stop the loop and return the regex match index.
         */
        thresholds.some((threshold) => {
            searchString = words.slice(0, threshold).join(' ');

            // Using "exec" instead of "test" to access the "match.index" property later
            const match = needleRegex.exec(searchString);

            if (match) {
                foundAt = match.index;
                return;
            }
        });

        if (foundAt === -1) {
            return -1;
        }

        // From the regex match index, we can count the number of words present before the needle
        const wordsBeforeNeedle = searchString.slice(0, foundAt).split(/\s+/).length;
        return wordsBeforeNeedle;
    };

    const targetKeywordScore = () => {
        const foundAt = targetKeywordSearch(targetKeyword, description);
        if (foundAt === -1 || foundAt > 300) return 0;
        const bracket = Math.ceil(foundAt / 100) * 100;
        return targetKeywordFoundScoreMap[Math.max(0, bracket / 100 - 1)];
    };

    const targetKeywordRepetitionScore = () => {
        if (!targetKeyword) return 0;
        const foundAtIndexes = [...description.matchAll(new RegExp(targetKeyword, 'gi'))].map(
            (a) => a.index,
        );
        const pointsMap = [0, 50, 75, 100];
        return pointsMap[Math.min(3, foundAtIndexes.length)];
    };

    const psoKeywordsFoundScore = () => {
        if (!psoConfiguration) return 0;
        const foundKeywords = psoConfiguration.keywords.filter((k: string) =>
            description.includes(k),
        );
        const pointsMap = [0, 50, 50, 75, 75, 100];
        return pointsMap[Math.min(5, foundKeywords.length)];
    };

    const descriptionLengthScore = () => {
        const pointsMap = [0, 50, 75, 100];
        const lengthMap = [0, 500, 1000, 2000];

        let lengthIndex = 0;
        for (let i = 0; i < lengthMap.length; i++) {
            if (lengthMap[i] <= description.length) {
                lengthIndex = i;
            } else {
                break;
            }
        }

        return pointsMap[lengthIndex];
    };

    const targetKeywordFoundRuleCompletion = targetKeywordScore();
    const targetKeywordRepetitionRuleCompletion = targetKeywordRepetitionScore();
    const psoKeywordsFoundRuleCompletion = psoKeywordsFoundScore();
    const descriptionLengthRuleCompletion = descriptionLengthScore();

    useEffect(() => {
        setScore(
            Math.round(
                (targetKeywordFoundRuleCompletion +
                    targetKeywordRepetitionRuleCompletion +
                    psoKeywordsFoundRuleCompletion +
                    descriptionLengthRuleCompletion) /
                    4,
            ),
        );
    }, [
        setScore,
        targetKeywordFoundRuleCompletion,
        targetKeywordRepetitionRuleCompletion,
        psoKeywordsFoundRuleCompletion,
        descriptionLengthRuleCompletion,
    ]);

    return (
        <Wrapper>
            <TitleWrapper>
                <FontAwesomeIcon icon={icon({ name: 'align-left', style: 'solid' })} />
                <FormattedMessage defaultMessage="Description" />
            </TitleWrapper>

            <RulesWrapper>
                <Rule
                    label={
                        <FormattedMessage defaultMessage="Inclure le mot-clé principal dans les 100 premiers mots" />
                    }
                    requiresTargetKeyword={true}
                    missingTargetKeyword={!targetKeyword}
                    completion={targetKeywordFoundRuleCompletion}
                />
                <Rule
                    label={
                        <FormattedMessage defaultMessage="Utiliser le mot-clé principal au moins 3 fois" />
                    }
                    requiresTargetKeyword={true}
                    missingTargetKeyword={!targetKeyword}
                    completion={targetKeywordRepetitionRuleCompletion}
                />
                <Rule
                    label={
                        <FormattedMessage defaultMessage="Inclure au moins 5 mots-clés de votre PSO Control Panel" />
                    }
                    completion={psoKeywordsFoundRuleCompletion}
                />
                <Rule
                    label={
                        <FormattedMessage defaultMessage="La description doit contenir au moins 2000 caractères" />
                    }
                    completion={descriptionLengthRuleCompletion}
                />
            </RulesWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const RulesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-m);
    font-weight: var(--fw-semibold);
    color: var(--black);
`;

export default DescriptionRules;
