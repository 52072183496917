import Model from './Model';
import { date } from './resolvers';

class SocialMediaInsightsModel extends Model {
    static attributes = {
        messageId: 'message_id',
        provider: 'provider',
        updatedAt: date('updated_at'),
        impression: 'impression',
        reach: 'reach',
        engagement: 'engagement',
        engagementReach: 'engagement_reach',
    };
}

export default SocialMediaInsightsModel;
