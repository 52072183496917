import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchPodcastListeningLinks } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeListeningLinkKeys from '@/queries/episodeListeningLink/episodeListeningLinkKeys';
import { z } from 'zod';

export const ListeningLinkSchema = z.object({
    key: z.string(),
    url: z.string(),
});

const ListeningLinksSchema = z.array(ListeningLinkSchema);

type InputProps = {
    episodeId: string;
};

type Key = QueryFunctionContext<ReturnType<typeof episodeListeningLinkKeys.listByEpisodeId>>;

export type Episode = z.infer<typeof ListeningLinkSchema>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await fetchPodcastListeningLinks(episodeId);

    return ListeningLinksSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useEpisodeListeningLinksQuery = ({ episodeId }: InputProps) => {
    return useQuery({
        queryKey: episodeListeningLinkKeys.listByEpisodeId(episodeId),
        queryFn,
        enabled: !!episodeId,
    });
};

export default useEpisodeListeningLinksQuery;
