import { action, runInAction } from 'mobx';
import debounce from 'lodash.debounce';
import Store from './Store';
import {
    addRssImport,
    checkImport,
    deleteRssImport,
    fetchRssImport,
    fetchRssInfo,
    updateRssImport,
    importShow,
    fetchAppleShows,
} from '@/api';
import RssInfoModel from '../models/RssInfoModel';
import RssImportModel from '../models/RssImportModel';
import { apiCall, apiFetch } from '../components/app/decorators/api';
import ShowModel from '../models/ShowModel';
import AppleShowModel from '../models/AppleShowModel';

class ImportRssStore extends Store {
    static observables = {
        rssInfo: null,
        importRss: null,
        importCount: null,
        results: [],
        // UI
        checkedUrl: null,
        searchError: null,
    };

    // UI
    @action.bound
    removeRssInfo() {
        this.rssInfo = null;
    }

    // API
    @apiCall
    importShow(body) {
        return importShow(this.state.userStore.user.channelId, body).then(({ data }) => {
            runInAction(() => {
                const { showStore } = this.state;
                const show = new ShowModel(showStore, data);
                showStore.shows.push(show);
                showStore.showAdding = show;
                this.state.userStore.user.showsCount += 1;
                this.state.routerStore.replace('show.import.success');
            });
        });
    }

    @apiCall
    async checkImport(showId, formData) {
        const { data } = await checkImport(showId, formData);
        runInAction(() => {
            this.importCount = data.new;
            this.checkedUrl = formData.url;
        });
    }

    @apiFetch
    async fetchRssImport(showId) {
        try {
            const { data } = await fetchRssImport(showId);
            runInAction(() => {
                this.importRss = new RssImportModel(this, data);
            });
        } catch (e) {
            this.importRss = null;
        }
    }

    @apiCall
    async fetchRssInfo(url) {
        const { data } = await fetchRssInfo({ url });
        runInAction(() => {
            this.rssInfo = new RssInfoModel(this, data);
        });
    }

    @apiCall
    async addRssImport(showId, params) {
        return addRssImport(showId, params).then((data) => {
            runInAction(() => {
                this.importRss = new RssImportModel(this, data);
            });
        });
    }

    @apiCall
    async updateImport(showId, params) {
        await deleteRssImport(showId);
        return this.addRssImport(showId, params);
    }

    @apiCall
    async updateRssAutoImport(showId, params) {
        const { data } = await updateRssImport(showId, params);
        runInAction(() => {
            this.importRss = new RssImportModel(this, data);
        });
    }

    @apiCall
    async hideImportBanner(showId) {
        const { data } = await updateRssImport(showId, { is_banner_hidden: true });
        runInAction(() => {
            this.importRss = new RssImportModel(this, data);
        });
    }

    debouncedRssSearch = debounce((query) => this.searchAppleShows(query), 300);

    @apiCall
    async searchAppleShows(query) {
        if (!query || query.length < 3) {
            this.results = [];
            return;
        }
        try {
            const { data } = await fetchAppleShows(query);
            runInAction(() => {
                this.results = data.map((show) => new AppleShowModel(this, show));
                this.searchError = null;
            });
        } catch (e) {
            this.searchError = e;
        }
    }

    @action.bound
    resetLink() {
        this.checkedUrl = null;
    }
}

export default ImportRssStore;
