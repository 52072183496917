import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { usePsoScoreContext } from '@/context/PsoScoreContext';
import CircularProgress from '@ui/atoms/CircularProgress';
import styled, { css } from 'styled-components';

interface ScoreProps {
    confirmModalMode?: boolean;
}

const Score = ({ confirmModalMode }: ScoreProps) => {
    const confirmModal = confirmModalMode ?? false;
    const { psoScore } = usePsoScoreContext();
    const score = psoScore ?? 0;
    const scoreThresholds = [
        {
            threshold: 0,
            color: '--alert',
            title: <FormattedMessage defaultMessage="Faible" />,
            description: (
                <FormattedMessage defaultMessage="Commencez à optimiser le référencement de votre épisode en suivant les recommandations. 💪" />
            ),
        },
        {
            threshold: 25,
            color: '--warning',
            title: <FormattedMessage defaultMessage="Moyen" />,
            description: (
                <FormattedMessage defaultMessage="Continuez comme ça ! Afin d’augmenter votre score, suivez les recommandations. 🚀" />
            ),
        },
        {
            threshold: 70,
            color: '--success',
            title: <FormattedMessage defaultMessage="Élevé" />,
            description: (
                <FormattedMessage defaultMessage="Bien joué ! Le référencement de votre épisode est optimal. 🥳" />
            ),
        },
    ];

    let scoreIndex = 0;
    for (let i = 0; i < scoreThresholds.length; i++) {
        if (scoreThresholds[i].threshold <= score) {
            scoreIndex = i;
        } else {
            break;
        }
    }

    const reachedThreshold = scoreThresholds[scoreIndex];

    return (
        <Wrapper confirmModal={confirmModal} color={`${reachedThreshold.color}50`}>
            <HeaderWrapper>
                <TitleWrapper confirmModal={confirmModal}>
                    {!confirmModal && (
                        <TitleIcon icon={icon({ name: 'chart-simple', style: 'solid' })} />
                    )}
                    <Title>
                        <FormattedMessage defaultMessage="PSO Score" />
                    </Title>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <HelpIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <FormattedMessage
                                defaultMessage="Ce score indique dans quelle mesure le titre, la description et les tags de votre épisode sont optimisés pour la visibilité dans les recherches. <link>En savoir plus sur le PSO Score</link>."
                                values={{
                                    link: (chunks: string) => (
                                        <TooltipLink
                                            href="http://www.ismycomputeron.com/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {chunks}
                                        </TooltipLink>
                                    ),
                                }}
                            />
                        </Tooltip>
                    </TooltipTrigger>
                </TitleWrapper>
            </HeaderWrapper>
            <ContentWrapper>
                <ContentTextWrapper>
                    <ScoreTitle color={reachedThreshold.color}>{reachedThreshold.title}</ScoreTitle>
                    <ScoreDescription>{reachedThreshold.description}</ScoreDescription>
                </ContentTextWrapper>
                <GraphWrapper>
                    <CircularProgress
                        size={96}
                        percentage={score}
                        backgroundColor={
                            confirmModal
                                ? `${reachedThreshold.color}100`
                                : `${reachedThreshold.color}50`
                        }
                        progressColor={reachedThreshold.color}
                        strokeWidth={14}
                    >
                        <ScoreText color={reachedThreshold.color}>{score}%</ScoreText>
                    </CircularProgress>
                </GraphWrapper>
            </ContentWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div<{ confirmModal: boolean; color?: string }>`
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: var(--r-m);
    background-color: var(--white);
    gap: 1.5rem;

    ${(props) =>
        props.confirmModal &&
        props.color &&
        css`
            padding: 1rem;
            gap: 0.5rem;
            background-color: var(${props.color});
        `}
`;
const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const TitleWrapper = styled.div<{ confirmModal: boolean }>`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    ${(props) =>
        props.confirmModal &&
        css`
            color: var(--neutral500);
        `}
`;
const TitleIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;
const Title = styled.div`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-semibold);
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const TooltipLink = styled.a`
    color: inherit;
`;
const ContentWrapper = styled.div`
    display: flex;
    gap: 1.5rem;
    align-items: center;
`;
const ContentTextWrapper = styled.div`
    text-align: left;
`;
const GraphWrapper = styled.div`
    flex-basis: 6rem;
    flex-grow: 1;
    flex-shrink: 0;
`;
const ScoreTitle = styled.h2<{ color: string }>`
    color: ${(props) => `var(${props.color})`};
    font-size: var(--fs-heading-m);
    font-weight: var(--fw-bold);
`;
const ScoreDescription = styled.p`
    color: var(--neutral500);
`;
const ScoreText = styled.span<{ color: string }>`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-bold);
    color: ${(props) => `var(${props.color})`};
`;

export default Score;
