import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IMG_TYPES } from '@/utils/constants';
import Stack from '@/components/ui/layout/Stack';
import Text from '@/components/ui/atoms/Text';
import { DropImgUploaderController } from '@/components/DropUploader';
import styled from 'styled-components';

const EpisodeEditFormNewsletterImageField = () => {
    const { control } = useFormContext();

    return (
        <Stack $gap="0.5rem">
            <Text fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Image d’illustration" />
            </Text>
            <DropImgUploader
                control={control}
                name="file"
                acceptedFileTypes={(IMG_TYPES as unknown as string[]) || []}
            >
                <Stack $gap="1rem" $align="center">
                    <DropImgIcon icon={icon({ name: 'image', style: 'duotone' })} />
                    <Stack $gap="0.5rem" $align="center">
                        <Text fontWeight="--fw-semibold" textAlign="center">
                            <FormattedMessage
                                defaultMessage="Glissez-déposez votre image, ou <label>Parcourir</label>"
                                values={{
                                    label: (chunks: React.ReactNode) => (
                                        <Text
                                            as="span"
                                            color="--primary"
                                            fontWeight="--fw-semibold"
                                        >
                                            {chunks}
                                        </Text>
                                    ),
                                }}
                            />
                        </Text>
                        <Text color="--neutral500" textAlign="center">
                            <FormattedMessage
                                defaultMessage="
.JPG ou .PNG, 640 x 360px min. (1280 x 720px recommandé), jusqu’à {max} Mo"
                                values={{ max: 3 }}
                            />
                        </Text>
                    </Stack>
                </Stack>
            </DropImgUploader>
        </Stack>
    );
};

const DropImgUploader = styled(DropImgUploaderController)`
    height: 15rem;
    position: relative; // To fix some RAC's position absolute div behind the hood breaking the episode form layout
`;
const DropImgIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--primary);
`;

export default EpisodeEditFormNewsletterImageField;
