import styled from 'styled-components';
import IconButton from '@/components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import EpisodeAIBubblePopoverContent from './EpisodeAIBubblePopoverContent';

interface EpisodeAIBubblePopoverProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
}

const EpisodeAIBubblePopover = (props: EpisodeAIBubblePopoverProps) => {
    const handleClose = () => props.onOpenChange(false);

    return (
        <AIPopover data-testid="episode-ai-bubble-modal" $isOpen={props.isOpen}>
            <EpisodeAIBubblePopoverContent />
            <Footer>
                <CloseButton
                    variant="ghost"
                    icon={<CloseIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
                    onPress={handleClose}
                    data-testid={'episode-ai-bubble-modal-close-button'}
                    aria-label="Close AI Bubble Popover"
                />
            </Footer>
        </AIPopover>
    );
};

const AIPopover = styled.div<{ $isOpen: boolean }>`
    display: none;
    position: fixed;
    bottom: 5rem;
    right: 0.5rem;
    left: 0.5rem;
    z-index: 100;
    flex-direction: column;
    background: var(--gradient-aurora);
    border-radius: var(--r-l);

    ${({ $isOpen }) => $isOpen && ` display: flex; `}
`;
const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const CloseButton = styled(IconButton)`
    padding: 0 1.5rem 1.5rem 1.5rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
`;

export default EpisodeAIBubblePopover;
