import { action, runInAction } from 'mobx';
import Store from './Store';
import { deleteShowListeningLink } from '@/api';
import { YOUTUBE, AMAZON_MUSIC, APPLE_PODCASTS, SPOTIFY } from '@/utils/constants';

class BroadcastStore extends Store {
    static observables = {
        broadcastName: '',
        broadcastKey: undefined,
        isTutorial: false,
        isSettings: false,
        showModalSuccessBroadcast: false,
        showYoutubeLoginModal: false,
        isCustomBroadcastContentModal: false,
        showProviderLoginModal: false,
        plugOnBroadcast: 0,
        openStepTutorial: {
            itunes: true,
            tuneIn: true,
            iHeartRadio: true,
            pandora: true,
            playerFm: true,
            jioSaavn: true,
            gaana: true,
            radioPublic: true,
        },
        tooltipIsView: {
            spotify: false,
            deezer: false,
            podcastAddict: false,
            podchaser: false,
            listenNotes: false,
            podcastIndex: false,
        },
        successBroadcast: {
            itunes: false,
            spotify: false,
            deezer: false,
            podcastAddict: false,
            tuneIn: false,
            youTube: false,
            pocketCasts: true,
            castbox: true,
            overcast: true,
            castro: true,
            amazon: false,
            podchaser: false,
            listenNotes: false,
            podcastIndex: false,
            iHeartRadio: false,
            pandora: false,
            playerFm: false,
            jioSaavn: false,
            gaana: false,
            radioPublic: false,
        },
        broadcastNoTutoList: [
            'Spotify',
            'Deezer',
            'Podcast Addict',
            'Pocket Casts',
            'Castbox',
            'Overcast',
            'Castro',
            'YouTube',
            'Soundcloud',
            'Amazon Music',
            'Podchaser',
            'Listen Notes',
            'Podcast Index',
        ],
        broadcastProvidersList: ['YouTube', 'SoundCloud'],
        broadcastCustomContentList: ['Amazon Music'], // If you want a custom message after the BroadcastConnectionChoiceModal put its name here
    };

    @action
    settings(isSettings, name, plugOnBroadcast) {
        this.isSettings = isSettings;
        if (name) {
            this.setTutorial(name);
            this.formatBroadcastKey(name);
        }
        if (plugOnBroadcast) {
            this.plugOnBroadcast = 2;
        } else if (plugOnBroadcast === 0) {
            this.plugOnBroadcast = 0;
        } else {
            this.plugOnBroadcast = 1;
        }
    }

    @action
    setLastStepBeforeBroadcastModal(bool, { broadcastName }) {
        this.showModalSuccessBroadcast = bool;
        if (this.broadcastCustomContentList.includes(broadcastName))
            this.isCustomBroadcastContentModal = bool;
    }

    @action
    setStartSettingBroadcastName(bool, broadcastName) {
        if (this.broadcastProvidersList.includes(broadcastName)) this.showProviderLoginModal = bool;
        else this.setLastStepBeforeBroadcastModal(bool, { broadcastName });

        this.setTutorial(broadcastName);
        this.formatBroadcastKey(broadcastName);
    }

    setTutorial(name) {
        const found = this.broadcastNoTutoList.find((broadcast) => broadcast === name);
        this.isTutorial = found !== name;
    }

    formatBroadcastKey(name = '') {
        this.broadcastName = name;
        const arrayName = [];
        for (let i = 0; i < name.length; i += 1) {
            if (i === 0) {
                const formatName = name[0].toLowerCase();
                arrayName.push(formatName);
            } else {
                arrayName.push(name[i]);
            }
        }
        this.broadcastKey = arrayName.join('').replace(' ', '');

        if (this.broadcastKey === 'applePodcasts') this.broadcastKey = APPLE_PODCASTS;
        if (this.broadcastKey === 'amazonMusic') this.broadcastKey = AMAZON_MUSIC;
        if (this.broadcastKey === 'playerFM') this.broadcastKey = 'playerFm';
        if (this.broadcastKey === 'youTube') this.broadcastKey = YOUTUBE;
    }

    // SET TUTORIAL VIEW
    @action
    setStepTutorial(key, val) {
        const tutorial = localStorage.getItem('tutorial-ausha-broadcast');
        const obj = JSON.parse(tutorial || '{}');
        localStorage.setItem('tutorial-ausha-broadcast', JSON.stringify({ ...obj, [key]: val }));
        this.openStepTutorial[key] = val;
    }

    // SET TOOLTIP VIEW
    @action
    setTooltipView(key, val) {
        const tooltip = localStorage.getItem(`tooltip-ausha-broadcast`);
        const obj = JSON.parse(tooltip || '{}');
        localStorage.setItem(`tooltip-ausha-broadcast`, JSON.stringify({ ...obj, [key]: val }));
        this.tooltipIsView[key] = val;
    }

    // SET SUCCESS BROADCAST
    @action
    setsuccessBroadcast(key, val) {
        localStorage.setItem(
            `successBroadcast-ausha-broadcast`,
            JSON.stringify({ ...this.successBroadcast, [key]: val }),
        );
        this.successBroadcast[key] = val;
    }

    async unbroadcastOnSpotify(show) {
        await deleteShowListeningLink(show.id, SPOTIFY);
        runInAction(() => {
            show.listeningLinks.spotify = '';
            this.isSettings = false;
        });
    }
}

export default BroadcastStore;
