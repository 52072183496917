import Button from '@/components/Button';
import IconButton from '@/components/IconButton';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { formatPrice } from '@/helpers';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface EpisodeAISubscriptionModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
}

const EpisodeAISubscriptionModal = ({ isOpen, onOpenChange }: EpisodeAISubscriptionModalProps) => {
    const subscription = useSubscriptionQuery();
    const currency = subscription.data?.prices?.base.match(/[$€£]/)?.[0] || '$';
    const YEARLY_PRICE = 12.5;

    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled
            data-testid="episode-ai-subscription-modal"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            size="medium"
        >
            <Header>
                <HeaderText>
                    <Text variant="headingM" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Ajouter Ausha Intelligence à mon offre" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Obtenez des titres d'épisodes, des chapitres, des posts sociaux et bien plus encore. ✨" />
                    </Text>
                </HeaderText>
                <CloseButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                />
            </Header>
            <Stack $gap="0.25rem">
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Option Ausha Intelligence (+ 60min supplémentaires gratuites de Transcription)" />
                </Text>
                <Text color="--neutral500">
                    <FormattedMessage
                        defaultMessage="{amount} /mois"
                        values={{
                            amount: formatPrice(YEARLY_PRICE, currency),
                        }}
                    />
                </Text>
            </Stack>
            <Actions>
                <Button variant="link-secondary" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
                <Button>
                    <FormattedMessage defaultMessage="Ajouter Ausha Intelligence" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
`;
const HeaderText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
const CloseButton = styled(IconButton)`
    align-self: flex-start;
    padding: 0;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 1.5rem;
    border-top: 1px solid var(--neutral100);
`;
export default EpisodeAISubscriptionModal;
