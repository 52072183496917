import { useState } from 'react';
import Button from '@ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage, useIntl } from 'react-intl';
import EpisodeMoveToDraftModal from '@app/molecules/EpisodeMoveToDraftModal';
import { useResponsive } from '@hooks/useResponsive';
import { EPISODE_STATUS } from '@/utils/constants';
import userKeys from '@queries/user/userKeys';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { useQueryClient } from '@tanstack/react-query';
import useInternalRouteMapping from '@hooks/useInternalRouteMapping.hook';
import { useFormContext } from 'react-hook-form';
import getMoveToDraftSchema from '@app/organisms/EpisodeEditForm/schemas/moveToDraftSchema';
import { getDirtyValues } from '@/helpers';
import useSaveEpisodeFormMutation from '@app/organisms/EpisodeEditForm/helpers/useSaveEpisodeFormMutation';
import EpisodeEditFormActionsTooltipMessage from '@app/organisms/EpisodeEditForm/actions/EpisodeEditFormActionsTooltipMessage';
import { getChaptersIdsRequiringImageDeletion } from '@app/organisms/EpisodeEditForm/helpers/getChaptersIdsRequiringImageDeletion';
import ExperimentalTooltip from '@ui/atoms/ExperimentalTooltip';
import styled from 'styled-components';

const MoveToDraftButton = () => {
    const intl = useIntl();
    const { isMobile } = useResponsive();
    const { push } = useInternalRouteMapping();
    const queryClient = useQueryClient();
    const {
        watch,
        reset,
        formState: { dirtyFields },
    } = useFormContext();
    const saveEpisodeFormMutation = useSaveEpisodeFormMutation();
    const { data: subscription } = useSubscriptionQuery();

    const [isModalOpened, setModalOpened] = useState(false);

    const episodeBeingEdited = useEpisodeBeingEdited();
    const schema = getMoveToDraftSchema(intl);
    const formData = watch();
    const isSchemaValid = schema.isValidSync(formData);
    const isValid = isSchemaValid;

    const onPress = () => {
        setModalOpened(true);
    };

    const handleMoveToDraft = async () => {
        const dirtyValues = getDirtyValues(dirtyFields, formData);
        const chaptersIdsRequiringImageDeletion = getChaptersIdsRequiringImageDeletion(
            dirtyFields,
            formData.chapters,
        );

        const { content, options, privacy } = dirtyValues;
        const episodeData = { ...content, ...options, ...privacy, state: EPISODE_STATUS.DRAFT };

        try {
            await saveEpisodeFormMutation.mutateAsync(
                {
                    episodeId: episodeBeingEdited.id,
                    episode: episodeData,
                    chapters: dirtyFields.chapters ? formData.chapters : null,
                    chaptersIdsRequiringImageDeletion,
                },
                {
                    onSuccess: () => {
                        reset();
                        if (subscription.isFreemium) {
                            queryClient.invalidateQueries({ queryKey: userKeys.detail() });
                        }
                        push('menu.episodes');
                    },
                },
            );
        } catch (error) {
            // Error toast ?
        }
    };

    return (
        <>
            <ExperimentalTooltip
                isDisabled={isValid}
                content={<EpisodeEditFormActionsTooltipMessage isSchemaValid={isSchemaValid} />}
            >
                <ButtonStyled
                    onPress={onPress}
                    isDisabled={!isValid}
                    startIcon={
                        <FontAwesomeIcon
                            icon={icon({ name: 'reply', style: 'solid' })}
                            color="inherit"
                        />
                    }
                >
                    {isMobile ? (
                        <FormattedMessage defaultMessage="Dépl. vers brouillons" />
                    ) : (
                        <FormattedMessage defaultMessage="Déplacer vers brouillons" />
                    )}
                </ButtonStyled>
            </ExperimentalTooltip>
            <EpisodeMoveToDraftModal
                isOpen={isModalOpened}
                onOpenChange={setModalOpened}
                handleMoveToDraft={handleMoveToDraft}
                isLoading={saveEpisodeFormMutation.isLoading}
            />
        </>
    );
};

const ButtonStyled = styled(Button)`
    background-color: var(--primary50);
    color: var(--primary);
    box-shadow: none;
`;

export default MoveToDraftButton;
