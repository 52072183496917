import { useQuery } from '@tanstack/react-query';
import { fetchChannel } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import channelKeys from './channelKeys';
import { z } from 'zod';

export const ChannelSchema = z.object({
    id: z.number(),
    backgroundUrl: z.string().nullable(),
    canHostOnAusha: z.boolean(),
    createdAt: z.string(),
    logoUrl: z.string().nullable(),
    publicId: z.string(),
    showsCount: z.number(),
    updatedAt: z.string(),
    canInvite: z.boolean(),
    availableInvitationsNumber: z.number().nullable(),
    availableInvitationsNumberMax: z.number().nullable(),
    aiFreeTrialUsed: z.boolean(),
});
export type Channel = z.infer<typeof ChannelSchema>;

const queryFn = async () => {
    const { data } = await fetchChannel();
    // TODO: Remove this when API is ready
    data.aiFreeTrialUsed = false;
    // TODO: Do camelcase transformation in Axios interceptor
    return ChannelSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useChannelQuery = () => {
    return useQuery({
        queryKey: channelKeys.allDetails(),
        queryFn,
    });
};

export default useChannelQuery;
