import Model from './Model';

class AgeModel extends Model {
    static attributes = {
        age: 'age',
        count: 'count',
        percentage: 'percentage',
    };
}

export default AgeModel;
