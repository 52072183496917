import { useEffect, useState } from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import EpisodeAIPanelField from './EpisodeAIPanelField';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import useAITagsSuggestionsQuery from '@queries/ai/useAITagsSuggestionsQuery.hook';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import { useEpisodeAISettings } from '@/components/EpisodeAI/contexts/EpisodeAISettingsContext';

interface EpisodeAITagsFieldProps {
    generateButtonDataTestId: string;
}

const EpisodeAITagsField = ({ generateButtonDataTestId }: EpisodeAITagsFieldProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const [settings] = useEpisodeAISettings();
    const tagsSettings = settings.get('tags') || { customPrompt: '', saveAsDefault: false };
    const tagsSuggestionsQuery = useAITagsSuggestionsQuery({
        episodeId,
        settings: tagsSettings,
        delay: 2000,
    });
    const [isGenerated, setIsGenerated] = useState(
        localStorage.getItem(`episode-ai-${episodeId}-generated-tags`) === 'true',
    );
    const [state, dispatch] = useEpisodeAILoading();
    const isLoading = state.get('tags') || false;
    const episode = useEpisodeQuery({ episodeId, config: { enabled: !!episodeId } });

    // Once the tags suggestions are fetched, set the generated flag to true
    useEffect(() => {
        if (tagsSuggestionsQuery.data && !tagsSuggestionsQuery.isFetching) {
            localStorage.setItem(`episode-ai-${episodeId}-generated-tags`, 'true');
            setIsGenerated(true);
            dispatch({
                type: EPISODE_AI_LOADING_ACTIONS.STOP,
                payload: 'tags',
            });
        }
    }, [tagsSuggestionsQuery.data, tagsSuggestionsQuery.isFetching]);

    const handleGenerate = () => {
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.START,
            payload: 'tags',
        });
        tagsSuggestionsQuery.refetch();
    };

    return (
        <EpisodeAIPanelField
            data-testid={generateButtonDataTestId}
            icon={<Icon icon={icon({ name: 'hashtag', style: 'solid' })} />}
            type="tags"
            title={<FormattedMessage defaultMessage="Tags" />}
            description={
                <FormattedMessage defaultMessage="Des mots-clés pertinents pour votre visibilité." />
            }
            onAction={handleGenerate}
            isLoading={isLoading}
            isGenerated={isGenerated && !isLoading}
            isDisabled={isGenerated && episode.data?.aiFreeTrialEnabled}
            tooltip="💎 Add-on"
        />
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--primary-magic);
`;

export default EpisodeAITagsField;
