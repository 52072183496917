import { runInAction, action, computed } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { deleteShowListeningLink, fetchShows, switchShowQuotaReached } from '@/api';
import Store from './Store';
import ShowModel from '../models/ShowModel';
import { deleteShow, fetchShow, updateShow, updateShowListeningLinks } from '@/api';
import { apiFetch } from '../components/app/decorators/api';
import { sortArrayByAlphabeticalOrder } from '../helpers';
import { STATUS_SHOWS } from '../utils/statusShows';
import { PRICING } from '../utils/pricing';
import { decamelizeKeys } from 'humps';
import { queryClient } from '@/components/Root';
import showKeys from '@/queries/show/showKeys';
import showListeningLinkKeys from '@/queries/showListeningLink/showListeningLinkKeys';

const { ACTIVE_SHOWS, ARCHIVED_SHOWS, GUEST_SHOWS } = STATUS_SHOWS;

class ShowStore extends Store {
    static observables = {
        shows: [],
        // Sorted shows
        showsActive: [],
        showsArchived: [],
        showsGuest: [],
        // UI
        showAdding: null,
        showToSelect: null,
    };

    byId = createTransformer((showId) => this.shows.find((s) => `${s.id}` === `${showId}`));
    byPublicId = createTransformer((id) => this.shows.find((s) => `${s.publicId}` === `${id}`));

    // UI

    @action.bound
    setShowToSelect(publicId) {
        this.showToSelect = this.byPublicId(publicId);
    }

    @action.bound
    resetAdding() {
        this.showAdding = null;
    }

    // API

    @apiFetch
    async fetchShows(channelId) {
        const { data } = await fetchShows(channelId);
        runInAction(() => {
            this.shows = data.map((show) => new ShowModel(this, show));

            this.showsActive = sortArrayByAlphabeticalOrder(
                this.shows.filter((show) => show.showStatus === ACTIVE_SHOWS && show),
                'name',
            );
            this.showsArchived = sortArrayByAlphabeticalOrder(
                this.shows.filter((show) => show.showStatus === ARCHIVED_SHOWS && show),
                'name',
            );
            this.showsGuest = sortArrayByAlphabeticalOrder(
                this.shows.filter((show) => show.showStatus === GUEST_SHOWS && show),
                'name',
            );

            const { locationPath, push } = this.state.routerStore;
            if (!locationPath.match(/\/app(\/(show\/?)?)?$/gi)) return;
            if (this.shows.length > 1) {
                push('show.all');
            } else if (this.shows.length > 0) {
                push('menu.episodes', { showId: (this.showToSelect || this.shows[0]).id });
            } else {
                push('show.new');
            }

            this.showToSelect = null;
        });
    }

    @apiFetch
    async fetchShow(showId) {
        const { data } = await fetchShow(showId);
        const showIndex = this.shows.findIndex((show) => show.id === showId);
        if (showIndex) {
            this.shows[showIndex] = new ShowModel(this, data);
        } else {
            this.shows.push(data);
        }
    }

    setCreatedShow(show, image = null) {
        const formattedShow = new ShowModel(this, decamelizeKeys(show));
        if (image) {
            formattedShow.imageUrl = image.url;
        }
        this.shows.push(formattedShow);
        this.state.userStore.user.showsCount += 1;
        this.showAdding = formattedShow;
    }

    async deleteShow(show) {
        await deleteShow(show.id);
        runInAction(() => {
            this.shows.remove(show);
            this.state.userStore.user.showsCount -= 1;
        });
    }

    async markAsDeleted(show) {
        runInAction(() => {
            this.shows.remove(show);
            this.state.userStore.user.showsCount -= 1;
        });
    }

    async updateListeningLinks(show, formData) {
        // Sending an empty string for distribution platforms is not supported anymore by the API.
        // Instead, a DELETE API call should be used to remove distribution platforms links.
        const listeningPlatformsToDelete = Object.entries(formData)
            .filter(([_, platformLink]) => platformLink === '')
            .map(([platformName, _]) => platformName);

        const formDataWithoutPlatformsToDelete = formData;
        for (const listeningPlatform of listeningPlatformsToDelete) {
            await deleteShowListeningLink(show.id, listeningPlatform);
            // This distribution platform is now deleted and should be removed from data passed to the following PUT API call.
            delete formDataWithoutPlatformsToDelete[listeningPlatform];
        }

        const { data } = await updateShowListeningLinks(show.id, formDataWithoutPlatformsToDelete);

        const hasSpotifyOrApple = data.some((link) => ['spotify', 'apple'].includes(link.key));

        if (hasSpotifyOrApple || data.length === 0) {
            queryClient.invalidateQueries({
                queryKey: showListeningLinkKeys.listByShowId(show.id),
            });
            queryClient.invalidateQueries({
                queryKey: showKeys.all(),
            });
        }

        const link = data.reduce((acc, elt) => ({ ...acc, [elt.key]: elt.url }), {});
        show.listeningLinks.updateData({ show_id: show.id, ...link });
    }

    async switchShowQuotaReached(show, showTo) {
        await switchShowQuotaReached(show, showTo);
        await this.fetchShows(this.state.userStore.user.channelId);
    }

    async switchStatusShow(showId) {
        const show = await this.shows.find((showToSwitch) => showToSwitch.id === showId);
        await updateShow(showId, { archived: !show.archived });
        await this.fetchShows(this.state.userStore.user.channelId);
    }

    @computed
    get statusAllShows() {
        // ACTIVE_SHOWS
        const activatedShowsMax = this.state.subscriptionStore.subscription?.showsMax;
        const extraShows = this.state.subscriptionStore.subscription?.extraShows;
        const totalShowsActive = this.showsActive.length;
        // ARCHIVED_SHOWS
        const archivedShowsMax = this.state.subscriptionStore.subscription?.archivedShowsMax;
        const totalShowsArchived = this.showsArchived.length;
        // GUEST_SHOWS
        const totalShowsGuest = this.showsGuest.length;

        return {
            activeShows: {
                isActive: totalShowsActive > 0,
                totalShowsActive,
                activatedShowsMax: activatedShowsMax + extraShows,
                canAddShow: totalShowsActive < activatedShowsMax + extraShows,
                canArchived: totalShowsArchived < archivedShowsMax,
                isAnEnterprise:
                    this.state.subscriptionStore.subscription?.pricing === PRICING.ENTERPRISE,
            },
            archivedShows: {
                isActive: totalShowsArchived > 0,
                totalShowsArchived,
                archivedShowsMax,
                canActivated: totalShowsActive < activatedShowsMax + extraShows,
            },
            guestShows: { isActive: totalShowsGuest > 0, totalShowsGuest },
        };
    }

    @computed
    get hasOwnShows() {
        return this.showsActive.length > 0 || this.showsArchived.length > 0;
    }
}

export default ShowStore;
