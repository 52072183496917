import Model from './Model';
import { dateUTC } from './resolvers';

class RssImportModel extends Model {
    static attributes = {
        id: 'id',
        showId: 'show_id',
        url: 'url',
        auto: 'auto',
        isBannerHidden: 'is_banner_hidden',
        originalPlatform: 'original_platform',
        syncedAt: dateUTC('synced_at'),
        createdAt: dateUTC('created_at'),
        updatedAt: dateUTC('updated_at'),
    };
}

export default RssImportModel;
