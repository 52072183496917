import { useMutation, useQueryClient } from '@tanstack/react-query';
import { psoScoreUpdateTargetKeyword } from '@/api';
import psoScoreKeys from './psoScoreKeys';
import camelcaseKeys from 'camelcase-keys';

interface MutationFnProps {
    episodeId: string;
    keyword: string | null;
}

const mutationFn = async ({ episodeId, keyword }: MutationFnProps) => {
    const { data } = await psoScoreUpdateTargetKeyword(episodeId, keyword);

    return camelcaseKeys(data, { deep: true });
};

export const useSaveTargetKeywordMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (_, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: psoScoreKeys.targetKeyword(episodeId),
            });
            queryClient.invalidateQueries({
                queryKey: psoScoreKeys.aiKeywords(episodeId),
            });
        },
    });
};

export default useSaveTargetKeywordMutation;
