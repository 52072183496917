import { useMutation, useQueryClient } from '@tanstack/react-query';
import { psoScoreDeleteTargetKeyword } from '@/api';
import psoScoreKeys from './psoScoreKeys';
import camelcaseKeys from 'camelcase-keys';

interface MutationFnProps {
    episodeId: string;
}

const mutationFn = async ({ episodeId }: MutationFnProps) => {
    const { data } = await psoScoreDeleteTargetKeyword(episodeId);

    return camelcaseKeys(data, { deep: true });
};

export const useDeleteTargetKeywordMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (_, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: psoScoreKeys.targetKeyword(episodeId),
            });
        },
    });
};

export default useDeleteTargetKeywordMutation;
