import styled from 'styled-components';
import Modal from '@ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import Stack from '@/components/ui/layout/Stack';
import Button from '@/components/Button';

interface EpisodeAIUnsubscribeModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
}
const EpisodeAIUnsubscribeModal = ({ isOpen, onOpenChange }: EpisodeAIUnsubscribeModalProps) => {
    const handleClose = () => onOpenChange(false);

    return (
        <UnsubscribeModal size="small" isOpen={isOpen} onOpenChange={onOpenChange}>
            <IconWrapper>
                <FontAwesomeIcon icon={icon({ name: 'heart-crack', style: 'solid' })} />
            </IconWrapper>
            <Stack $gap="0.25rem" $align="center">
                <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage defaultMessage="Êtes-vous sûr de vouloir supprimer Ausha Intelligence ?" />
                </Text>
                <Text color="--neutral500" textAlign="center">
                    <FormattedMessage defaultMessage="Vous continuerez à bénéficier de l'add-on jusqu'au {date}. Après cette date, vous ne serez plus facturé et vous perdrez l'accès à toutes les fonctionnalités d'Ausha Intelligence." />
                </Text>
            </Stack>
            <Stack $gap="1rem" $align="stretch">
                <Button variant="danger">
                    <FormattedMessage defaultMessage="Supprimer l'add-on" />
                </Button>
                <CancelButton variant="ghost" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Conserver l'add-on" />
                </CancelButton>
            </Stack>
        </UnsubscribeModal>
    );
};

const UnsubscribeModal = styled(Modal)`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1.5rem;
`;
const IconWrapper = styled.div`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--alert50);
    border-radius: var(--r-m);
    color: var(--alert500);
    align-self: center;
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;

export default EpisodeAIUnsubscribeModal;
