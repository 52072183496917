import styled from 'styled-components';
import Modal from '@ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import IconButton from '@/components/IconButton';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, defineMessages } from 'react-intl';
import { AIFieldType } from '../types';
import EpisodeAICustomPrompt from '../EpisodeAICustomPrompt/EpisodeAICustomPrompt';

const titles = defineMessages({
    title: { defaultMessage: 'Définir les préférences de AI pour le titre' },
    description: { defaultMessage: 'Définir les préférences de AI pour la description' },
    tags: { defaultMessage: 'Définir les préférences de AI pour les tags' },
    newsletter: { defaultMessage: 'Définir les préférences de AI pour la newsletter' },
    socialPost: { defaultMessage: 'Définir les préférences de AI pour le post' },
});

const subtitles = defineMessages({
    title: {
        defaultMessage:
            'Personnalisez la façon dont AI génère les titres de vos épisodes en lui donnant des instructions spécifiques.',
    },
    description: {
        defaultMessage:
            'Personnalisez la façon dont AI génère les descriptions de vos épisodes en lui donnant des instructions spécifiques.',
    },
    tags: {
        defaultMessage:
            'Personnalisez la façon dont AI génère les tags de vos épisodes en lui donnant des instructions spécifiques.',
    },
    newsletter: {
        defaultMessage:
            'Personnalisez la façon dont AI génère les newsletters de vos épisodes en lui donnant des instructions spécifiques.',
    },
    socialPost: {
        defaultMessage:
            'Personnalisez la façon dont AI génère les posts de vos épisodes en lui donnant des instructions spécifiques.',
    },
});

interface EpisodeAICustomPromptModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    type: AIFieldType;
}
const EpisodeAICustomPromptModal = ({
    isOpen,
    onOpenChange,
    type,
}: EpisodeAICustomPromptModalProps) => {
    const onClose = () => onOpenChange(false);

    return (
        <AIModal variant="ai" size="medium" isOpen={isOpen} onOpenChange={onOpenChange}>
            <Header>
                <HeaderText>
                    <Text variant="headingM" fontWeight="--fw-bold">
                        <FormattedMessage {...titles[type]} />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage {...subtitles[type]} />
                    </Text>
                </HeaderText>
                <CloseButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={onClose}
                    variant="ghost"
                    aria-label="Close modal"
                />
            </Header>
            <EpisodeAICustomPrompt onClose={onClose} type={type} />
        </AIModal>
    );
};

const AIModal = styled(Modal)`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 1.5rem;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
`;
const HeaderText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
const CloseButton = styled(IconButton)`
    align-self: flex-start;
    padding: 0;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;

export default EpisodeAICustomPromptModal;
