import { runInAction } from 'mobx';
import Store from './Store';
import { deleteImportedEpisode, fetchImportedPodcasts, retryImport } from '@/api';
import ImportedPodcastModel from '../models/ImportedPodcastModel';
import ImportedPodcastsCountersModel from '../models/ImportedPodcastsCountersModel';
import { apiCall } from '../components/app/decorators/api';
import PaginationModel from '../models/PaginationModel';
import { modalToastQueue } from '@/components/ui/molecules/GlobalToastRegions';
import { UI_VARIANTS } from '@/utils/constants';
import { FormattedMessage } from 'react-intl';

class ImportedPodcastsStore extends Store {
    static observables = {
        importedPodcasts: [],
        counters: {},
        pagination: {},
        isLoading: false,
    };

    @apiCall
    async fetchImportedPodcasts(importId, query) {
        return this.subscribeToImportedPodcasts(importId, query);
    }

    @apiCall
    async subscribeToImportedPodcasts(importId, query) {
        const { data, meta } = await fetchImportedPodcasts(importId, query);
        runInAction(() => {
            this.importedPodcasts = data.map((p) => new ImportedPodcastModel(this, p));
            this.counters = new ImportedPodcastsCountersModel(this, meta.counters);
            this.pagination = new PaginationModel(this, meta.pagination);
        });
    }

    async retryImport(importedPodcast) {
        importedPodcast.states = 'pending';
        await retryImport(importedPodcast.id);
    }

    async retryAllImports() {
        runInAction(() => {
            this.isLoading = true;
        });

        const promises = this.importedPodcasts.map((podcast) => {
            if (podcast.states !== 'error') return;
            podcast.states = 'pending';
            return retryImport(podcast.id);
        });

        await Promise.all(promises);

        runInAction(() => {
            this.isLoading = false;
            modalToastQueue.add(
                <FormattedMessage defaultMessage="L'import des épisodes en erreur a bien été relancé. Si l'erreur persiste, n'hésitez pas à contacter notre support. 🙏" />,
                { variant: UI_VARIANTS.SUCCESS, timeout: 5000 },
            );
        });
    }

    async deleteImportedEpisode(importedPodcast) {
        importedPodcast.states = 'pending';
        await deleteImportedEpisode(importedPodcast.id);
    }
}

export default ImportedPodcastsStore;
