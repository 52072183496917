import { useIntl } from 'react-intl';
import { z } from 'zod';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';

const useCTAFormSchema = () => {
    const intl = useIntl();
    return z.object({
        label: z
            .string({
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            })
            .min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required))
            .max(40, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 40 })),
        url: z.string().url(intl.formatMessage(FORM_VALIDATION_MESSAGE.isUrl)),
        targets: z
            .array(z.enum(['podcast_page', 'smartlink', 'newsletter', 'player']), {
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            })
            .min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
    });
};

export type CTAFormSchema = z.infer<ReturnType<typeof useCTAFormSchema>>;
export default useCTAFormSchema;
