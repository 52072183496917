import { runInAction } from 'mobx';
import dayjs from 'dayjs';
import Store from './Store';
import { apiCall, apiFetch } from '../components/app/decorators/api';
import { addPersonalAccessToken, deletePersonalAccessToken, fetchPersonalAccessToken } from '@/api';
import PersonalAccessTokenModel from '../models/PersonalAccessTokenModel';

class PersonalAccessTokenStore extends Store {
    static observables = {
        personalAccessTokens: [],
        currentPersonalAccessToken: {},
    };

    @apiFetch
    async fetchPersonalAccessToken() {
        const { data } = await fetchPersonalAccessToken();
        runInAction(() => {
            this.personalAccessTokens = data.map(
                (accessToken) => new PersonalAccessTokenModel(this, accessToken),
            );
        });
    }

    @apiCall
    async addPersonalAccessToken({ name, expirationDate }) {
        // Kept current date to looking for it in personalAccessTokens array
        const currentDate = dayjs.utc().format('DD/MM/YYYY HH:MM');

        const { access_token: personalAccessToken } = await addPersonalAccessToken({
            name,
            expires_at: dayjs(expirationDate).format('YYYY-MM-DD'),
        });
        runInAction(async () => {
            await this.fetchPersonalAccessToken();
            this.currentPersonalAccessToken = {
                personalAccessToken,
                expirationDate: dayjs(
                    this.personalAccessTokens.find(
                        (c) => dayjs.utc(c?.createdAt).format('DD/MM/YYYY HH:MM') === currentDate,
                    ).expiresAt,
                ).format('DD/MM/YYYY'),
            };
        });
    }

    @apiCall
    async deletePersonalAccessToken(tokenId) {
        await deletePersonalAccessToken(tokenId);
        runInAction(() => {
            this.personalAccessTokens.remove(
                this.personalAccessTokens.find((element) => element.id === tokenId),
            );
        });
    }
}

export default PersonalAccessTokenStore;
