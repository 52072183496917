import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import Spinner from '../../ui/atoms/Spinner';
import useDeleteCallToActionButtonMutation from '@/queries/callToAction/useDeleteCallToActionButtonMutation.hook';

const CTADeleteButtonModal = ({ isOpened, onClose, state }) => {
    const deleteCta = useDeleteCallToActionButtonMutation();
    return (
        <DeprecatedModal
            isOpened={isOpened}
            onClose={onClose}
            maxW={400}
            title={<FormattedMessage defaultMessage="Souhaitez-vous supprimer ce bouton ?" />}
        >
            <DeprecatedText>
                <FormattedMessage defaultMessage="Il ne sera plus visible sur vos outils de communication." />
            </DeprecatedText>
            <DeprecatedPaper flex mt={40} justify="flex-end" gap={8}>
                <DeprecatedButton accent="secondary" onClick={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </DeprecatedButton>
                <DeprecatedButton
                    onClick={() => {
                        deleteCta.mutate(
                            { ctaId: state.item.id },
                            {
                                onSettled: () => {
                                    onClose();
                                },
                            },
                        );
                    }}
                    disabled={deleteCta.isLoading || deleteCta.isFetching}
                >
                    {(deleteCta.isLoading || deleteCta.isFetching) && <Spinner />}
                    <FormattedMessage defaultMessage="Supprimer" />
                </DeprecatedButton>
            </DeprecatedPaper>
        </DeprecatedModal>
    );
};

export default CTADeleteButtonModal;
