import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import styled from 'styled-components';
import SparklesIcon from '@public/icons/sparkles-nebula.svg';
import GiftIcon from '@public/icons/gift-nebula.svg';
import { FormattedMessage } from 'react-intl';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { formatPrice } from '@/helpers';
import illustration from '@public/images/ai-pricing.webp';
import EpisodeAIPricingAction from './EpisodeAIPricingAction';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { USER_OPTIONS } from '@/utils/userOptions';
import Badge from '@/components/ui/atoms/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const EpisodeAIPricing = () => {
    const user = useUserQuery();
    const subscription = useSubscriptionQuery();
    const currency = subscription.data?.prices?.base.match(/[$€£]/)?.[0] || '$';
    const YEARLY_PRICE = 12.5;
    const MONTHLY_PRICE = 15;
    const hasActiveAddOn = user.data?.options.includes(USER_OPTIONS.EPISODE_AI);

    return (
        <Container>
            <Stack $gap="2rem">
                <Stack $gap="1.5rem">
                    <Header>
                        <HeaderInner>
                            <Text color="--neutral500" fontWeight="--fw-semibold">
                                Add-on
                            </Text>
                            {hasActiveAddOn && (
                                <Badge
                                    variant="success"
                                    icon={
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'circle-check', style: 'solid' })}
                                        />
                                    }
                                >
                                    <FormattedMessage defaultMessage="Ajouté" />
                                </Badge>
                            )}
                        </HeaderInner>
                        <Title>Ausha Intelligence</Title>
                    </Header>
                    <BulletList>
                        <BulletPoint>
                            <IconSparkles />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="Génération automatique de <b>titres d'épisodes, descriptions, tags, chapitres et newsletters.</b>"
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </BulletPoint>
                        <BulletPoint>
                            <IconSparkles />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="Génération automatique de posts sur les <b>réseaux sociaux.</b>"
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </BulletPoint>
                        <BulletPoint>
                            <IconSparkles />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="<b>Tonalité et préférences</b> de la génération AI personnalisables."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </BulletPoint>
                        <BulletPoint>
                            <IconGift />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="<b>+60 minutes</b> de Transcription additionnelles par mois."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </BulletPoint>
                    </BulletList>
                    {hasActiveAddOn ? (
                        <Alert data-testid="pricing-ai-freemium">
                            <AlertIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage
                                    defaultMessage="L'add-on AI a bien été ajouté à votre offre. Vous serez facturé {amount} de plus par mois."
                                    values={{
                                        amount: formatPrice(YEARLY_PRICE, currency),
                                    }}
                                />
                            </Text>
                        </Alert>
                    ) : (
                        <Stack $gap="0.25rem">
                            <Text variant="headingL" fontWeight="--fw-bold">
                                <FormattedMessage
                                    defaultMessage="{amount} /mois"
                                    values={{
                                        amount: formatPrice(YEARLY_PRICE, currency),
                                    }}
                                />
                            </Text>
                            <Text color="--neutral500">
                                <FormattedMessage
                                    defaultMessage="Facturation annuelle ou {amount} par mois."
                                    values={{
                                        amount: formatPrice(MONTHLY_PRICE, currency),
                                    }}
                                />
                            </Text>
                        </Stack>
                    )}
                </Stack>
                <EpisodeAIPricingAction />
            </Stack>
            <Illustration src={illustration} alt="Ausha Intelligence" />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    background: var(--gradient-aurora);
    border-radius: var(--r-m);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        column-gap: 2rem;

        & > :first-child {
            flex-grow: 1;
        }
        & > :last-child {
            flex-shrink: 0;
        }
    }
`;
const Header = styled.header`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const HeaderInner = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Title = styled.h4`
    font-size: var(--fs-heading-l);
    font-weight: var(--fw-bold);
    background: var(--gradient-nebula);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;
const BulletList = styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    list-style: none;
    padding: 0;
`;
const BulletPoint = styled.li`
    display: flex;
    column-gap: 0.5rem;
`;
const IconSparkles = styled(SparklesIcon)`
    width: 0.75rem;
    height: 0.75rem;
    flex-shrink: 0;
    margin-top: 0.25rem;
`;
const AlertIcon = styled(FontAwesomeIcon)`
    margin-top: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
`;
const IconGift = styled(GiftIcon)`
    width: 0.75rem;
    height: 0.75rem;
    flex-shrink: 0;
    margin-top: 0.25rem;
`;
const Alert = styled.div`
    display: flex;
    column-gap: 0.5rem;
    color: var(--info500);
`;
const Illustration = styled.img`
    width: 25rem;
`;

export default EpisodeAIPricing;
