import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import useUpdateCallToActionButtonMutation from '@/queries/callToAction/useUpdateCallToActionButtonMutation.hook';

const CTADeleteLocationModal = ({ isOpened, onClose, state }) => {
    const updateCta = useUpdateCallToActionButtonMutation();
    return (
        <DeprecatedModal
            isOpened={isOpened}
            onClose={onClose}
            maxW={400}
            title={<FormattedMessage defaultMessage="Souhaitez-vous supprimer ce bouton ?" />}
        >
            <DeprecatedText>
                <FormattedMessage
                    defaultMessage="Il ne sera plus visible sur votre {type}."
                    values={{ type: state.locationSelected.label }}
                />
            </DeprecatedText>
            <DeprecatedPaper flex mt={40} justify="flex-end" gap={8}>
                <DeprecatedButton accent="secondary" onClick={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </DeprecatedButton>
                <DeprecatedButton
                    onClick={() => {
                        updateCta.mutate(
                            {
                                ctaId: state.item.id,
                                cta: {
                                    label: state.item.text,
                                    targets: state.item.locations.filter(
                                        (location) => location !== state.locationSelected.value,
                                    ),
                                },
                                type: state.item.type,
                            },
                            {
                                onSettled: () => {
                                    onClose();
                                },
                            },
                        );
                    }}
                >
                    <FormattedMessage defaultMessage="Supprimer" />
                </DeprecatedButton>
            </DeprecatedPaper>
        </DeprecatedModal>
    );
};

export default CTADeleteLocationModal;
