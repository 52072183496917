import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import aiKeys from '@queries/ai/aiKeys';
import { fetchAICustomPrompts } from '@/api';
import { z } from 'zod';
import camelcaseKeys from 'camelcase-keys';

const AICustomPromptSchema = z.object({
    field: z.string(),
    prompt: z.string(),
});
const AICustomPromptsSchema = z.array(AICustomPromptSchema);

export type AICustomPrompt = z.infer<typeof AICustomPromptSchema>;

type Key = QueryFunctionContext<ReturnType<typeof aiKeys.customPrompts>>;
const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchAICustomPrompts(showId);
    return AICustomPromptsSchema.parse(camelcaseKeys(data, { deep: true }));
};

interface useAICustomPromptsQueryProps {
    showId: string;
}

const useAICustomPromptsQuery = ({ showId }: useAICustomPromptsQueryProps) => {
    return useQuery({
        queryKey: aiKeys.customPrompts(showId),
        queryFn,
    });
};

export default useAICustomPromptsQuery;
