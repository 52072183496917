import { computed } from 'mobx';
import Model from './Model';
import { dateUTC } from './resolvers';
import { apiCall } from '../components/app/decorators/api';

class ImportedPodcastModel extends Model {
    static attributes = {
        id: 'id',
        showId: 'show_id',
        podcastId: 'podcast_id',
        guid: 'guid',
        url: 'url',
        title: 'title',
        states: 'state',
        publishedAt: dateUTC('published_at'),
        createdAt: dateUTC('created_at'),
        updatedAt: dateUTC('updated_at'),
    };

    @computed
    get show() {
        return this.state.showStore.byId(this.showId);
    }

    @computed
    get podcast() {
        return this.state.podcastStore.byId(this.podcastId);
    }

    @apiCall
    retry() {
        return this.store.retryImport(this);
    }
}

export default ImportedPodcastModel;
