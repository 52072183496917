import { useState } from 'react';
import styled from 'styled-components';
import EpisodeAISuggestionsPanel from '../EpisodeAISuggestionsPanel';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import aiKeys from '@/queries/ai/aiKeys';
import { useAccordionItem } from '@/components/ui/atoms/AccordionItem/useAccordionItem.hook';
import { useParams } from 'react-router';
import { useEpisodeAILoading } from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';
import useAITagsSuggestionsQuery from '@queries/ai/useAITagsSuggestionsQuery.hook';
import { useEpisodeAISettings } from '@/components/EpisodeAI/contexts/EpisodeAISettingsContext';
import { sendAmplitudeLogEvent } from '@/helpers';

interface EpisodeAITagsSuggestionsPanelProps {
    maxTags: number;
}

const EpisodeAITagsSuggestionsPanel = ({ maxTags }: EpisodeAITagsSuggestionsPanelProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const [settings] = useEpisodeAISettings();
    const tagsSettings = settings.get('tags') || { customPrompt: '', saveAsDefault: false };
    const tagsSuggestionsQuery = useAITagsSuggestionsQuery({
        episodeId,
        settings: tagsSettings,
        delay: 2000,
    });
    const [state] = useEpisodeAILoading();
    const isLoading = state.get('tags') || false;
    const queryClient = useQueryClient();
    const { watch, setValue } = useFormContext();
    const tags = watch('content.tags');
    const hasReachedMaxTags = tags.length === maxTags;
    const [addedTags, setAddedTags] = useState<string[]>([]);
    const { open, isExpanded } = useAccordionItem();

    if (open && !isExpanded && tagsSuggestionsQuery.isRefetching) {
        open();
    }

    // If the query is not fetching and there is no data, suggestions panel should not be displayed
    if (!tagsSuggestionsQuery.data && !isLoading) return null;

    // Remove duplicates and tags that are already selected
    const suggestions = Array.from(new Set(tagsSuggestionsQuery.data?.suggestions)).filter(
        (suggestion) => !addedTags.includes(suggestion),
    );

    const handleClose = () => {
        // Reset the suggestions query when the panel is closed
        queryClient.resetQueries({ queryKey: aiKeys.tags() });
        setAddedTags([]);
    };

    const handleSetSuggestion = (suggestion: string) => {
        if (hasReachedMaxTags) return;

        let newTags = [...tags];
        const index = newTags.indexOf(suggestion);

        // Remove the tag if it already exists
        if (index !== -1) {
            newTags.splice(index, 1);
        }

        newTags = [...newTags, suggestion];
        setValue('content.tags', newTags, { shouldDirty: true });
        setAddedTags((tags) => [...tags, suggestion]);

        // Close the panel if the suggestion was the only one left
        if (suggestions.length === 1) {
            handleClose();
        }
    };

    const handleDislike = () => {
        sendAmplitudeLogEvent('AI Dislike (tags)', {
            custom_prompt: tagsSettings.customPrompt || '',
            suggestions: tagsSuggestionsQuery.data?.suggestions,
        });
    };

    return (
        <EpisodeAISuggestionsPanel
            title={
                <FormattedMessage defaultMessage="Choisissez vos tags parmi les suggestions ci-dessus" />
            }
            type="tags"
            isLoading={isLoading}
            onClose={handleClose}
            data-testid="episode-ai-tags-suggestions-panel"
            onDislike={handleDislike}
        >
            <SuggestionsList $isDisabled={hasReachedMaxTags}>
                {suggestions?.map((suggestion) => (
                    <Suggestion onClick={() => handleSetSuggestion(suggestion)} key={suggestion}>
                        <Text fontWeight="--fw-semibold">{suggestion}</Text>
                    </Suggestion>
                ))}
            </SuggestionsList>
        </EpisodeAISuggestionsPanel>
    );
};

const SuggestionsList = styled.ul<{ $isDisabled: boolean }>`
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    ${({ $isDisabled }) =>
        $isDisabled &&
        `
        pointer-events: none;
        opacity: 0.5;
    `}
`;

const Suggestion = styled.li`
    width: fit-content;
    padding: 0.5rem 0.75rem;
    background-color: var(--white500);
    border-radius: var(--r-m);
    cursor: pointer;

    &:hover {
        background-color: var(--white);
    }
`;

export default EpisodeAITagsSuggestionsPanel;
