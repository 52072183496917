import { useEffect } from 'react';

interface UseLoadContentProps {
    editor: any | undefined; // TODO: Replace any with right type when InputRichText is typed
    isLoading: boolean;
    loadingContent: string;
    onLoadingEnd: () => void;
}

export const useLoadContent = ({
    editor,
    isLoading,
    loadingContent,
    onLoadingEnd,
}: UseLoadContentProps) => {
    useEffect(() => {
        if (!editor) return;
        if (loadingContent.length > 0) {
            let sentences: string[] = loadingContent.match(/[^.!?]+[.!?]*/g) || [];
            // let words = loadingContent.split(' ');
            // Clear the content of the editor before starting to write the message
            editor.commands.clearContent();
            if (isLoading) {
                const interval = setInterval(() => {
                    if (sentences.length > 0) {
                        // If there are still words to display, pop the next word at the end of the text input and move loading cursor
                        const [nextSentence, ...rest] = sentences;
                        editor
                            .chain()
                            .clearContentLoading()
                            .focus('end')
                            .insertContent(`${nextSentence} `)
                            .insertContentLoading()
                            .run();
                        sentences = rest;
                    } else {
                        onLoadingEnd();
                    }
                }, 300);
                return () => clearInterval(interval);
            } else {
                editor.commands.clearContent();
                editor.chain().clearContentLoading().insertContent(loadingContent).run();
            }
        }
        // All words are displayed and writing of the message is complete
        editor.chain().clearContentLoading().run();
    }, [editor, isLoading, loadingContent]);
};
