import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchSeasons } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import seasonKeys from '@/queries/season/seasonKeys';
import { z } from 'zod';

export const SeasonSchema = z.object({
    id: z.number(),
    podcastsCount: z.number().nullable().optional(),
    seasonNumber: z.number().nullable().optional(),
    showId: z.number().nullable().optional(),
    episodesCount: z.number().nullable().optional(),
});
const SeasonsSchema = z.array(SeasonSchema);
export type Season = z.infer<typeof SeasonSchema>;
type Key = QueryFunctionContext<ReturnType<typeof seasonKeys.detailById>>;
type InputProps = {
    showId: string;
};

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchSeasons(showId);
    const seasons = data.map((s: any) => {
        return {
            ...s,
            episodesCount: s.podcasts_count,
        };
    });

    return SeasonsSchema.parse(camelcaseKeys(seasons, { deep: true }));
};

const useSeasonsQuery = ({ showId }: InputProps) => {
    return useQuery({
        queryKey: seasonKeys.detailById(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useSeasonsQuery;
